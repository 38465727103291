import React, { useState } from 'react';
import ReviewItem from 'components/fc-self-service/components/review-item';
import Question from 'components/fc-self-service/components/question';

const SignatureRequired = (props: any) => {
  const { response, isReview, currentAnswers, onEdit, classes } = props;
  const [isConfirmationQuestion, setIsConfirmationQuestion] = useState(false);

  const keyName = 'signature_required';
  const questionText = 'Would you like to require a signature for your delivery?';
  const confirmationText = 'Please confirm you would like to require a signature upon delivery';
  const confirmationYesText =
    'That is correct and I understand the package will not be left without a signature';
  const confirmationNoText = 'Never mind, I am okay with not requiring a signature';

  const localResponse = (value: any) => {
    const responseValue = { keyName, value };
    if (value === 1 && !isConfirmationQuestion) {
      setIsConfirmationQuestion(true);
    } else {
      response(responseValue);
    }
  };

  if (!isReview) {
    if (!isConfirmationQuestion) {
      return (
        <Question
          questionText={questionText}
          response={localResponse}
          keyName={keyName}
          buttons={{ button1Text: 'No', button1Value: 0, button2Text: 'Yes', button2Value: 1 }}
          helperText={
            <>
              By choosing <span style={{ fontWeight: 'bold' }}>&quot;Yes&quot;</span>, your package
              will not be released without a signature at the time of delivery.
              <br />
              By choosing <span style={{ fontWeight: 'bold' }}>&quot;No&quot;</span>, you confirm it
              is okay for your package to be left without a signature. Please note we may need to
              require a signature if your insurance company or other situation requires it.
            </>
          }
        />
      );
    }
    return (
      <Question
        questionText={confirmationText}
        response={localResponse}
        keyName={keyName}
        buttons={{
          button1Text: confirmationNoText,
          button1Value: 0,
          button2Text: confirmationYesText,
          button2Value: 1,
        }}
        helperText=""
      />
    );
  }

  return (
    <ReviewItem
      questionText={questionText}
      onEdit={() => onEdit(1)}
      currentAnswer={currentAnswers[keyName] ? 'Yes' : 'No'}
      keyName={keyName}
    />
  );
};

export default SignatureRequired;
