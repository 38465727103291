import React from 'react';
import { Grid, Typography, Button } from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { Icon } from 'react-icons-kit';
import { flask as FlaskIcon } from 'react-icons-kit/fa/flask';
import { ICounselingTask } from 'interfaces/redux/task-types/ICounselingTask';

import LabValueCard from './lab-value-card';
import { IPatientReviewData } from '../../chart-review/interfaces/IProps';

import { styles } from './lab-values-list.styles';
import AddLabValuesModal, { ISelectableLab } from './add-lab-values-modal';
import { IObjectiveDataCollect } from '../interfaces/IProps';

interface IProps extends WithStyles<typeof styles> {
  dataCollects: IObjectiveDataCollect[];
  selectedLabs: ISelectableLab[];
  onSaveLabValues(labValues: ISelectableLab[]): void;
  onDeleteSelectedLabValue(taskId: number, dataTypeName: string): void;
  patientReviewData: IPatientReviewData;
  taskQty?: number;
  currentSelectedTasks?: ICounselingTask[];
}

const LabValuesList: React.FC<IProps> = (props: IProps) => {
  const { classes, patientReviewData, taskQty, currentSelectedTasks } = props;

  const [addValuesModalOpen, setAddValuesModalOpen] = React.useState<boolean>(false);

  const openAddLabValuesModal = () => {
    setAddValuesModalOpen(true);
  };

  const closeAddLabValuesModal = () => {
    setAddValuesModalOpen(false);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        {props.dataCollects
          .filter(dc =>
            // Filtering to only DCs that have at least 1 lab selected
            props.selectedLabs.some(selectedLab => selectedLab.taskId === dc.taskId),
          )
          .map(dataCollect => {
            return (
              <Grid
                key={`${dataCollect.taskId}_lab_values_list`}
                container
                className={classes.container}
              >
                <Grid item xs={12} className={classes.titleWrapper}>
                  <Typography className={classes.titleText}>
                    {`Data Collect ${dataCollect.drugName} ${dataCollect.reviewedDate}`}
                  </Typography>
                </Grid>
                {dataCollect.labs
                  // Filtering to only labs that are selected from this DC
                  .filter(lab =>
                    props.selectedLabs.some(
                      selectedLab =>
                        selectedLab.taskId === dataCollect.taskId &&
                        selectedLab.dataTypeName === lab.dataTypeName,
                    ),
                  )
                  .map(lab => (
                    <Grid key={`${dataCollect}_${lab.dataTypeName}_card_wrapper`} item xs={12}>
                      <LabValueCard
                        onClose={() =>
                          // Unselect lab value
                          props.onDeleteSelectedLabValue(dataCollect.taskId, lab.dataTypeName)
                        }
                        {...lab}
                      />
                    </Grid>
                  ))}
              </Grid>
            );
          })}
      </Grid>
      <Grid item xs={12}>
        <div className={classes.titleWrapper}>
          <Button
            disabled={!props.dataCollects.length}
            variant="outlined"
            startIcon={<Icon icon={FlaskIcon} size={22} />}
            onClick={openAddLabValuesModal}
          >
            {props.selectedLabs.length ? 'Edit Lab Values' : 'Add Lab Values'}
          </Button>
          <AddLabValuesModal
            open={addValuesModalOpen}
            handleCloseModal={closeAddLabValuesModal}
            dataCollects={props.dataCollects}
            onSaveLabValues={props.onSaveLabValues}
            initialSelectedLabs={props.selectedLabs}
            patientReviewData={patientReviewData}
            taskQty={taskQty}
            currentSelectedTasks={currentSelectedTasks}
          />
        </div>
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(LabValuesList);
