import React, { useState, useEffect } from 'react';
import {
  Grid,
  Typography,
  SnackbarContent,
  Chip,
  DialogContent,
  DialogActions,
  Button,
  CircularProgress,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import RichTextEditor, { EditorValueFormat, StandardFeatures } from 'components/rich-text-editor';
import { Field, reduxForm, InjectedFormProps, change } from 'redux-form';
import AutocompleteMinigrid from 'components/form/field/render-minigrid-autocomplete';
import { fetchGpis, specialtyTypeFormatter } from 'services/utils/therapy-service';
import { renderDropdown, renderTextField } from 'components/form/field/redux-field';
import { ILabelValueNumber } from 'interfaces/ILabelValue';
import { buildQaId } from 'utils/build-qa-id';
import { useDispatch } from 'react-redux';
import { ApplicationManagerClient } from 'clients/application-manager-client';
import { required } from 'components/form/validation/validation';
import { DiseaseState } from 'models/application-manager/cpmp/IGetDiseaseStateResponse';
import { IPostCslProtocolRequest } from 'models/application-manager/cpmp/IPostCslProtocolRequest';
import { AxiosPromise } from 'axios';
import { CslPlan } from './types';
import { ADD_SNACKBAR_TXT, FORM_NAME, QA_ID } from './constants';
import { CslProtocolFormModalStyles } from './styles';

export interface IProps {
  onSuccess?: () => void;
  onError?: (error: unknown) => void;
  onCancel?: () => void;
  protocol?: CslPlan;
  editMode?: boolean;
}

type ICslProtocolFormProps = IProps & InjectedFormProps<IPostCslProtocolRequest, IProps>;

const qaIdBuilder = buildQaId(QA_ID);

const useStyles = makeStyles(CslProtocolFormModalStyles);

const CslFormCsl = (props: ICslProtocolFormProps) => {
  const { onSuccess, onError, onCancel, handleSubmit, protocol, editMode } = props;
  const classes = useStyles();
  const dispatch = useDispatch();

  const [diseaseStates, setDiseaseStates] = useState<DiseaseState[]>([]);
  const [diseaseStatesOptions, setDiseaseStatesOptions] = useState<ILabelValueNumber[]>([]);
  const [loadingDiseaseStates, setLoadingDiseaseStates] = useState<boolean>(false);
  const [icdCodes, setIcdCodes] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const submitPromiseHandler = async (promise: AxiosPromise) => {
    try {
      setLoading(true);

      await promise;

      if (onSuccess) {
        onSuccess();
      }
    } catch (error) {
      if (onError) {
        onError(error);
      }
    } finally {
      setLoading(false);
    }
  };

  const onSubmit = async (formValues: Partial<IPostCslProtocolRequest>) => {
    if (!editMode) {
      return submitPromiseHandler(ApplicationManagerClient.createCslProtocol(formValues));
    }
      if (!protocol?.id) {
        return submitPromiseHandler(
          (() => {
            throw Error('Protocol is not selected for edit mode');
          })(),
        );
      }

      return submitPromiseHandler(
        ApplicationManagerClient.updateCslProtocol(protocol.id, formValues),
      );
  };

  const loadDiseaseStates = async () => {
    setLoadingDiseaseStates(true);
    try {
      const result = await ApplicationManagerClient.fetchDiseaseStates();
      if (result.data.result.length > 0) {
        setDiseaseStates(result.data.result);
      }
    } finally {
      setLoadingDiseaseStates(false);
    }
  };

  const loadDiseaseStateOptions = () => {
    setDiseaseStatesOptions(
      diseaseStates.map(({ diseaseName, id }) => ({
        label: diseaseName,
        value: id,
      })),
    );
  };

  const setFieldValue = (field: string, value: any) => dispatch(change(FORM_NAME, field, value));

  const onRichEditorChange = (field: string) => (value: string) => setFieldValue(field, value);

  const handleFetchGpisOptions = async (searchText: string) => {
    const result = await fetchGpis(searchText);
    return (
      result?.data?.drugs?.map((therapy: { ndc: any; drug_info: any; is_specialty_drug: any }) => ({
        ...therapy,
        id: therapy.ndc,
        label: therapy.drug_info,
      })) || []
    );
  };

  const setInitialMedication = async () => {
    if (!protocol?.gpi_10) {
      return;
    }
    const result = await fetchGpis(protocol.gpi_10, true, true);
    const medication = result?.data?.drugs?.[0];

    if (!medication) {
      return;
    }

    setFieldValue('medication', {
      ...medication,
      id: medication.ndc,
      label: medication.drug_info,
    });

    setFieldValue('gpi_10', medication.gpi.substr(0, 10));
  };

  const setDiseaseState = () => {
    if (!protocol?.specitaly_id) {
      return;
    }

    setFieldValue('specialty_id', protocol.specitaly_id);
    const diseaseState = diseaseStates.find(d => d.id === protocol.specitaly_id);
    setIcdCodes(Object.values(diseaseState?.icdCode || {}).map(code => code.name));
  };

  useEffect(() => {
    loadDiseaseStates();
  }, []);

  useEffect(() => {
    loadDiseaseStateOptions();
  }, [diseaseStates]);

  useEffect(() => {
    setInitialMedication();
  }, [protocol?.gpi_10]);

  useEffect(() => {
    setDiseaseState();
  }, [protocol?.specitaly_id, diseaseStates]);

  return (
    <>
      <DialogContent>
        <form data-qa-id={qaIdBuilder('form')}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              {!editMode && (
                <SnackbarContent className={classes.snackbar} message={ADD_SNACKBAR_TXT} />
              )}
            </Grid>
            <Grid item xs={4}>
              <Field
                name="medication"
                label="Medication name"
                labelVariant="body1"
                component={AutocompleteMinigrid}
                fetchOptions={handleFetchGpisOptions}
                hint="Search by medication, GPI or NDC"
                columnsToShow={{
                  gpi: 'GPI',
                  drug_name: 'Drug Name',
                  dosage_form_description: 'Form',
                  dose: 'Dose',
                  ldd: 'LDD',
                  specialty_type: 'Specialty Type',
                  ndc: 'NDC',
                }}
                minSearchLength={2}
                disabled={editMode}
                valueFormatter={specialtyTypeFormatter}
                onChange={(value: any) => {
                  setFieldValue('gpi_10', value.gpi.substr(0, 10));
                }}
              />
            </Grid>
            <Grid item xs={8}>
              <Field name="gpi_10" label="Associated GPI" component={renderTextField} disabled />
            </Grid>
            <Grid item xs={4}>
              <Field
                name="specialty_id"
                label="Disease State *"
                component={renderDropdown}
                validate={[required]}
                disabled={loadingDiseaseStates}
                fields={diseaseStatesOptions}
                onChange={(value: any) => {
                  if (!value) {
                    return;
                  }

                  const diseaseState = diseaseStates.find(d => d.id === value);
                  setIcdCodes(Object.values(diseaseState?.icdCode || {}).map(code => code.name));
                }}
              />
            </Grid>
            <Grid item xs={8}>
              <Typography className={classes.icdFieldLabel}>Associated ICD-10</Typography>
              <Grid className={classes.icdWrp}>
                {icdCodes.map(code => (
                  <Chip className={classes.icdChip} label={code} key={code} />
                ))}
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Typography className={classes.contentFieldLabel}>Goals of therapy:</Typography>
              <RichTextEditor
                autoFocus={false}
                initialValue={protocol?.content?.expectations_and_goals}
                features={StandardFeatures}
                featuresBar
                onChangeValue={onRichEditorChange('expectations_and_goals')}
                initialValueFormat={EditorValueFormat.Html}
                outputFormat={EditorValueFormat.Html}
                placeHolder=""
              />
            </Grid>
            <Grid item xs={12}>
              <Typography className={classes.contentFieldLabel}>
                Disease state education:
              </Typography>
              <RichTextEditor
                autoFocus={false}
                initialValue={protocol?.content?.disease_state_education}
                features={StandardFeatures}
                featuresBar
                onChangeValue={onRichEditorChange('disease_state_education')}
                initialValueFormat={EditorValueFormat.Html}
                outputFormat={EditorValueFormat.Html}
                placeHolder=""
              />
            </Grid>
            <Grid item xs={12}>
              <Typography className={classes.contentFieldLabel}>Administration:</Typography>
              <RichTextEditor
                autoFocus={false}
                initialValue={protocol?.content?.administration}
                features={StandardFeatures}
                featuresBar
                onChangeValue={onRichEditorChange('administration')}
                initialValueFormat={EditorValueFormat.Html}
                outputFormat={EditorValueFormat.Html}
                placeHolder=""
              />
            </Grid>
            <Grid item xs={12}>
              <Typography className={classes.contentFieldLabel}>Storage disposal:</Typography>
              <RichTextEditor
                autoFocus={false}
                initialValue={protocol?.content?.storage_disposal}
                features={StandardFeatures}
                featuresBar
                onChangeValue={onRichEditorChange('storage_disposal')}
                initialValueFormat={EditorValueFormat.Html}
                outputFormat={EditorValueFormat.Html}
                placeHolder=""
              />
            </Grid>
            <Grid item xs={12}>
              <Typography className={classes.contentFieldLabel}>Side effects:</Typography>
              <RichTextEditor
                autoFocus={false}
                initialValue={protocol?.content?.side_effects}
                features={StandardFeatures}
                featuresBar
                onChangeValue={onRichEditorChange('side_effects')}
                initialValueFormat={EditorValueFormat.Html}
                outputFormat={EditorValueFormat.Html}
                placeHolder=""
              />
            </Grid>
            <Grid item xs={12}>
              <Typography className={classes.contentFieldLabel}>Contact:</Typography>
              <RichTextEditor
                autoFocus={false}
                initialValue={protocol?.content?.contact}
                features={StandardFeatures}
                featuresBar
                onChangeValue={onRichEditorChange('contact')}
                initialValueFormat={EditorValueFormat.Html}
                outputFormat={EditorValueFormat.Html}
                placeHolder=""
              />
            </Grid>
            <Grid item xs={12}>
              <Typography className={classes.contentFieldLabel}>Adherence:</Typography>
              <RichTextEditor
                autoFocus={false}
                initialValue={protocol?.content?.adherence}
                features={StandardFeatures}
                featuresBar
                onChangeValue={onRichEditorChange('adherence')}
                initialValueFormat={EditorValueFormat.Html}
                outputFormat={EditorValueFormat.Html}
                placeHolder=""
              />
            </Grid>
            <Grid item xs={12}>
              <Typography className={classes.contentFieldLabel}>
                Monitoring and follow up:
              </Typography>
              <RichTextEditor
                autoFocus={false}
                initialValue={protocol?.content?.monitoring_follow_up}
                features={StandardFeatures}
                featuresBar
                onChangeValue={onRichEditorChange('monitoring_follow_up')}
                initialValueFormat={EditorValueFormat.Html}
                outputFormat={EditorValueFormat.Html}
                placeHolder=""
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions className={classes.actionsWrp}>
        <Button onClick={onCancel}>Cancel</Button>
        <Button
          color="primary"
          variant="contained"
          onClick={handleSubmit(onSubmit)}
          disabled={loading}
        >
          Save {loading && <CircularProgress className={classes.progress} size={13} />}
        </Button>
      </DialogActions>
    </>
  );
};

export const CslModalInnerForm = reduxForm<IPostCslProtocolRequest, IProps>({ form: FORM_NAME })(
  CslFormCsl,
);
