import moment from 'moment';
import React, { useState } from 'react';
import classnames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import {
  Dialog,
  Grid,
  Typography,
  DialogTitle,
  IconButton,
  DialogContent,
  DialogActions,
  Button,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { getUserById } from 'services/utils/users-service';
import { Close as CloseIcon } from '@mui/icons-material';
import QuestionnaireForm from 'components/dynamic-form/task-forms/questionnaire-form';
import { convertToArborDate, dateFormatUI } from 'models/time/arbor-date';
import { getActionedByUserText } from 'utils/user-utils';
import { QuestionnaireClient } from 'clients/questionnaire';
import { useClinicalDataItem } from 'containers/patient/clinical/use-clinical-data';
import {
  saveQuestionnaireAnswers,
  submitQuestionnaire,
  saveClinicalData,
} from 'actions/action-clinical-data';
import { notifyError } from 'actions/action-notifications';
import { fetchTask } from 'actions/action-tasks';
import { styles } from './dc-questionnaire.styles';
import { logger } from '../../../winston-logger';
import { ClinicalDataClient } from '../../../clients/clinical-data';
import { RS } from '../../../constants';

const useStyles = makeStyles(styles);

export default function ({
  patientId,
  clinicalDataItemId,
  dataTypeField,
  disabled,
  onClose,
  workingAsGroup,
  shouldCreateNewQuestionnaire,
  setShouldCreateNewQuestionnaire,
  missingItemsToCreateState,
  newQuestionnaireWasCreated,
  setNewQuestionnaireWasCreated,
  setQuestionnaire,
  updateFormId,
  setClinicalObjectAndAnswers,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [saving, setSaving] = useState(false);
  const users = useSelector(state => state.lookups.users);
  const questionnaires = useSelector(state => state.lookups.patientQuestionnaires);

  // Retrieve this questionnaire's clinical data item details from state.
  const clinicalDataItem = useClinicalDataItem(
    patientId,
    clinicalDataItemId[0] ?? clinicalDataItemId,
  );

  // Get details from retrieved clinical data item
  const questionnaireData = clinicalDataItem?.questionnaire || {};
  const { questionnaireId, references, singleValueReferences } = dataTypeField || {};
  const { score, submittedDate, submittedBy, lastViewedDt } = questionnaireData;
  const isQuestionnaireSubmitted = Boolean(submittedDate);
  const isVisited = Boolean(lastViewedDt);
  const parsedAnswers = questionnaireData.answers || [];

  const user = submittedBy
    ? users && users.length > 0 && getUserById(submittedBy, users).display_name
    : 'Patient';
  const questionnaire = questionnaires[questionnaireId];

  const submit = async () => {
    try {
      const response = await QuestionnaireClient.submitQuestionnaire(
        patientId,
        questionnaireData.id,
        workingAsGroup,
      );
      const action = submitQuestionnaire(response.data);
      dispatch(action);
      if (response.data && response.data.length && response.data[0].taskRiskStratId) {
        dispatch(fetchTask(RS, response.data[0].taskRiskStratId));
      }
      onClose(questionnaireData.score, true);
    } catch (error) {
      logger.error(error);
      dispatch(notifyError('Unable to submit questionnaire.'));
    }
  };

  const excludeCompletedQuestionnaires = async listOfQuestionnaireIds => {
    if (listOfQuestionnaireIds && listOfQuestionnaireIds.length) {
      const response = await QuestionnaireClient.getActiveQuestionnaires(listOfQuestionnaireIds);
      if (response && Object.keys(response).length && response.data) {
        return response.data;
      }
    }
    return listOfQuestionnaireIds;
  };

  const createClinicalDataItem = async clinicalDataTypeId => {
    const newItems = clinicalDataTypeId.map(item => {
      const newItem = {
        assessmentDate: moment.utc().format('YYYY-MM-DD'),
        cannotCompleteReason: null,
        clinicalDataTypeId: item.dataTypeId,
        required: false,
        therapyId: item.therapyId,
        value: null,
      };
      if (item.dcTaskId) {
        newItem.taskDataCollectId = item.dcTaskId;
      } else if (item.rsTaskId) {
        newItem.taskRiskStratId = item.rsTaskId;
      }
      return newItem;
    });
    try {
      const response = await ClinicalDataClient.save(patientId, newItems);
      const responseData = response.data;
      return responseData;
    } catch (error) {
      logger.error(error);
      dispatch(notifyError('Failed to save clinical data'));
    }
    return null;
  };

  const totalText =
    questionnaireId === 24
      ? `Total YES Answers: ${saving ? '...' : score ?? 0}`
      : `Total: ${saving ? '...' : score ?? 0}`;
  const valid =
    questionnaire &&
    questionnaire.questions.every((question, index) => {
      if (question.isActive === false) {
        return true;
      }
      const answerForThisQuestion = parsedAnswers[index];
      // A non-answer may be either undefined or null.
      return answerForThisQuestion !== undefined && answerForThisQuestion !== null;
    });

  const getReferencePro2 = (reference, ind) => {
    if (ind === 0) {
      return `< 8: ${reference.value}`;
    }
    if (ind === 3) {
      return `> ${reference.from}: ${reference.value}`;
    }
    return `${reference.from} - ${reference.to}: ${reference.value}`;
  };

  return (
    <Dialog
      open
      maxWidth="md"
      className={classes.questionnaireModal}
      onClose={() => onClose(questionnaireData?.score, false)}
    >
      <DialogTitle className={classes.dialogTitle}>
        <Typography variant="h6">{`Questionnaire ${questionnaire.title}`}</Typography>
        <IconButton
          onClick={() => onClose(questionnaireData?.score, false)}
          className={classes.closeButton}
          size="large"
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <>
          <Typography variant="subtitle1">{questionnaire.description}</Typography>
          <QuestionnaireForm
            questionnaires={[questionnaire]}
            initialAnswers={{ [questionnaireId]: parsedAnswers }}
            disabled={disabled}
            onChange={async updatedAnswers => {
              if (
                !disabled &&
                (shouldCreateNewQuestionnaire === null ||
                  shouldCreateNewQuestionnaire === undefined) &&
                updatedAnswers &&
                Object.keys(updatedAnswers).length
              ) {
                setSaving(true);
                const answersForQuestionnaire = updatedAnswers[questionnaireId];
                try {
                  const wagList = await excludeCompletedQuestionnaires(workingAsGroup);
                  const response = await QuestionnaireClient.saveAnswers(
                    patientId,
                    questionnaireData.id,
                    answersForQuestionnaire,
                    wagList,
                  );
                  dispatch(saveQuestionnaireAnswers(response.data));
                  setSaving(false);
                } catch (error) {
                  logger.error(error);
                  dispatch(notifyError('Unable to save questionnaire answers.'));
                }
              } else if (
                !disabled &&
                shouldCreateNewQuestionnaire &&
                updatedAnswers &&
                Object.keys(updatedAnswers).length
              ) {
                const answersForQuestionnaire = updatedAnswers[questionnaireId];
                if (
                  newQuestionnaireWasCreated === null &&
                  questionnaireData.answers?.length &&
                  answersForQuestionnaire.length &&
                  answersForQuestionnaire.some(
                    (elem, ind) => elem != questionnaireData.answers[ind],
                  )
                ) {
                  // create new item
                  const newClinicalItemResult = await createClinicalDataItem(
                    missingItemsToCreateState,
                  );
                  if (newClinicalItemResult[0]) {
                    if (setClinicalObjectAndAnswers) {
                      setClinicalObjectAndAnswers({
                        clinicalObject: newClinicalItemResult[0],
                        answers: answersForQuestionnaire,
                      });
                    }
                    setNewQuestionnaireWasCreated(true);
                    const itemId = newClinicalItemResult.map(item => item.id);
                    const action = saveClinicalData(newClinicalItemResult);
                    dispatch(action);
                    const wagList = await excludeCompletedQuestionnaires(workingAsGroup);
                    const response = await QuestionnaireClient.saveAnswers(
                      patientId,
                      newClinicalItemResult[0]?.questionnaire.id,
                      answersForQuestionnaire,
                      wagList,
                    );
                    dispatch(saveQuestionnaireAnswers(response.data));
                    if (itemId && itemId.length > 0 && setQuestionnaire) {
                      setQuestionnaire({
                        dataTypeField,
                        clinicalDataItemsId: itemId,
                        workingAsGroup,
                      });
                    }
                    setShouldCreateNewQuestionnaire(null);
                  }
                  setSaving(false);
                  if (updateFormId) {
                    updateFormId(
                      missingItemsToCreateState[0].dataTypeId,
                      newClinicalItemResult[0].id,
                    );
                  }
                }
              }
            }}
          />
        </>
      </DialogContent>
      <DialogActions className={classes.footer}>
        <Grid container>
          <Grid item xs={8}>
            <Typography variant="h6">
              {questionnaireId === 24 ? 'What Does My AIRQ Score Mean?' : 'Reference Range'}
            </Typography>
            <Typography variant="body2">
              {(singleValueReferences || []).map(singleReference => (
                <div key={singleReference.text}>
                  {`${singleReference.value} = ${singleReference.text}`}
                </div>
              ))}
              {(references || []).map((reference, ind) => (
                <div
                  key={reference.value}
                  className={classnames({
                    [classes.selected]: score >= reference.from && score <= reference.to,
                  })}
                >
                  {questionnaireId !== 25
                    ? `${reference.from} to ${reference.to} = ${reference.value}`
                    : `${getReferencePro2(reference, ind)}`}
                </div>
              ))}
            </Typography>
            {questionnaireData?.additionalScoresValues !== null && (
              <Grid item xs={12}>
                <Grid item xs={12}>
                  <Typography variant="h10" data-qa-id="mental_category_value">
                    {`Mental Category: ${
                      JSON.parse(questionnaireData.additionalScoresValues).mentalCategory ?? ''
                    }`}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h10" data-qa-id="physical_category_value">
                    {`Physical Category: ${
                      JSON.parse(questionnaireData.additionalScoresValues).physicalCategory ?? ''
                    }`}
                  </Typography>
                </Grid>
              </Grid>
            )}
          </Grid>
          <Grid item xs={4} className={classes.footerRight}>
            {JSON.parse(questionnaireData?.additionalScoresValues || null) === null && (
              <Typography variant="h6" data-qa-id="questionnaire-total-score">
                {totalText}
              </Typography>
            )}
            {questionnaireData?.additionalScoresValues !== null && (
              <Grid item xs={12}>
                <Grid item xs={12}>
                  <Typography variant="h10" className={classes.multipleScore}>
                    {`Mental PROMIS-10 T-score: ${
                      JSON.parse(questionnaireData.additionalScoresValues).mentalPROMISScore ?? 0
                    }`}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h10" className={classes.multipleScore}>
                    {`Physical PROMIS-10 T-score: ${
                      JSON.parse(questionnaireData.additionalScoresValues).physicalPROMISScore ?? 0
                    }`}
                  </Typography>
                </Grid>
              </Grid>
            )}
            <Typography className={classes.savingFlag} data-qa-id="questionnaire-save-status">
              {saving !== null && (saving ? 'Saving...' : 'Saved')}
            </Typography>
            <Typography
              className={
                isQuestionnaireSubmitted ? classes.submitSuccessFlag : classes.submitIncompleteFlag
              }
              data-qa-id="questionnaire-submission-status"
            >
              {isQuestionnaireSubmitted
                ? getActionedByUserText({
                    action: 'Submitted',
                    date: submittedDate,
                    dateFormat: dateFormatUI,
                    user,
                    isDateUtc: true,
                  })
                : 'Not submitted'}
            </Typography>
            <Typography
              className={isVisited ? classes.submitSuccessFlag : classes.submitIncompleteFlag}
              data-qa-id="questionnaire-visit-status"
            >
              {isVisited
                ? `Last visited ${convertToArborDate(lastViewedDt, true).getUtcDate(true)}`
                : 'Not visited'}
            </Typography>
            <div className={classes.buttonContainer}>
              {!disabled && (
                <Button
                  color="primary"
                  variant="contained"
                  disabled={!valid}
                  onClick={async () => submit()}
                  data-qa-id="questionnaire-submit-button"
                >
                  Submit
                </Button>
              )}
            </div>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}
