export const FcQuestionsIdsMap = {
  ancillary_supplies: 1,
  changes_in_medication: 2,
  hospital_visit: 3,
  is_notice_privacy_practices_sent: 4,
  new_allergies: 5,
  new_infections: 6,
  new_medical_conditions: 7,
  patient_missed_doses: 8,
  patient_questions: 9,
  side_effects: 10,
  welcome_kit_sent: 11,
  wk_pp_received: 12,
};

export type ConstraintTypeNames =
  | 'customer_id_filter'
  | 'therapy_dispensing_status'
  | 'therapy_clinical_support_status'
  | 'therapy_type'
  | 'patient_welcome_kit'
  | 'welcome_kit_and_patient_privacy_docs_uploaded'
  | 'therapy_gpi10_constraint'
  | 'therapy_gpi14_constraint';

export enum ActionTypeNamesEnum {
  Question = 'question',
  CreatePriorAuthorizationTask = 'create_prior_authorization_task',
  CreateFinancialAssistanceTask = 'create_financial_assistance_task',
  CreateDrugUtilizationReviewTask = 'create_drug_utilization_review_task',
  CreateCounselingTask = 'create_counseling_task',
  CreateIntTask = 'create_intervention_task',
  CreateDataCollectTask = 'create_data_collect_task',
  CreateRiskStrategyTask = 'create_risk_strategy_task',
}

export type ActionTypeNames =
  | 'question'
  | 'create_prior_authorization_task'
  | 'create_financial_assistance_task'
  | 'create_drug_utilization_review_task'
  | 'create_counseling_task'
  | 'create_intervention_task'
  | 'create_data_collect_task'
  | 'create_risk_strategy_task';

export interface IGenericIdValueSet<T, S> {
  id: T;
  value: S;
}

export interface IGenericOption {
  label: string;
  value: number;
  isDisabled?: boolean;
}

export type IdValueSetType = IGenericIdValueSet<number, string>;

export interface IRuleEngineCondition {
  constraint_id: ConstraintTypeNames;
  fields: IdValueSetType[];
}

export interface IRuleEngineAction {
  action_id: ActionTypeNames;
  fields: IdValueSetType[];
}

export enum EventTypeEnum {
  TherapyAdded = 'TherapyAdded',
  TherapyEnrolled = 'TherapyEnrolled',
  PatientEnrolled = 'PatientEnrolled',
  InsuranceAdded = 'InsuranceAdded',
  PatientDocumentAdded = 'PatientDocumentAdded',
  PatientDocumentUpdated = 'PatientDocumentUpdated',
  PatientPreferencesUpdated = 'PatientPreferencesUpdated',
}

export const AllEventTypes = Object.values(EventTypeEnum);

export const TherapyLevelEventTypes = [
  EventTypeEnum.TherapyAdded,
  EventTypeEnum.PatientEnrolled,
  EventTypeEnum.TherapyEnrolled,
];

export interface IRuleEngine {
  id?: number;
  name: string;
  selected_trigger: EventTypeEnum; // translates to "event_type" in payload
  // review if we need just the ID or the whole object
  selected_conditions: Partial<IRuleEngineCondition>[]; // translates to "constraints" in payload
  // review if we need just the ID or the whole object
  selected_actions: Partial<IRuleEngineAction>[]; // translates to "actions" in payload
  updated_by?: number;
  status: string;
  // make it mandatory after form is created
  effective_date: Date; // translates to "start_dt" in payload
  // make it mandatory after form is created
  expiration_date: Date; // translates to "end_dt" in payload
}
