import { convertToArborDate } from 'models/time/arbor-date';
import { DUR } from 'constants/index';
import { MedicationStatus } from 'constants/enums';
import { getUserById } from 'services/utils/users-service';
import { IDurTask } from 'interfaces/redux/task-types/IDurTask';
import { useTypedSelector } from 'hooks/use-typed-selector';
import {
  usePatientVaccines,
  parseDurVaccines,
} from 'components/vaccinations/hooks/use-patient-vaccines';
import { DurStatus } from 'interfaces/enums/TaskStatuses/DrugUtilizationReviewStatus';
import { IDurSectionData } from '../chart-review/interfaces/IDurSection';

const useLatestDur = (): IDurSectionData | undefined => {
  const tasks = useTypedSelector(state => state.tasks.data);
  const allergies = useTypedSelector(state => state.allergies.allergiesList);
  const medications = useTypedSelector(state => state.medications.medicationList).filter(
    med => med.status_code === MedicationStatus.Active,
  );
  const users = useTypedSelector(state => state.lookups.users);
  const vaccineLookups = useTypedSelector(state => state.lookups.vaccines) || {};
  const vaccines = usePatientVaccines();
  const problems = useTypedSelector(state => state.patient?.problems || []);
  const durTasks = Object.values(tasks).filter(task => task?.taskType === DUR);

  if (!durTasks.length) {
    return undefined;
  }

  const mostRecentDurTask = durTasks.sort(
    (firstTask, secondTask) =>
      new Date(secondTask?.created ?? '')?.getTime() - new Date(firstTask.created ?? '')?.getTime(),
  )?.[0];

  const lastReviewUser = getUserById(mostRecentDurTask?.reviewed_by, users)?.username ?? '';

  const lastReviewDate =
    convertToArborDate(mostRecentDurTask?.reviewed_date ?? '').getCustomerDatetime(true) ?? '';

  // Pick current patient data from the redux state
  const durTask: IDurTask = {
    ...mostRecentDurTask,
    allergies,
    medications,
    vaccines,
    problems,
  };

  return {
    lastReviewDate,
    lastReviewUser,
    task:
      mostRecentDurTask?.status_id === DurStatus.Reviewed
        ? parseDurVaccines(vaccineLookups, mostRecentDurTask)
        : durTask,
  };
};

export { useLatestDur };
