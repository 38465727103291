import React, { useState, useMemo } from 'react';
import ReviewItem from 'components/fc-self-service/components/review-item';
import Question from 'components/fc-self-service/components/question';
import { STRINGS } from '../../fc_ss_constants';

const keyName = 'preferred_shipping_address';
const questionText = 'Please select the address for your delivery';
const questionTextReview = 'Delivery Address';

const DeliveryAddress = (props: any) => {
  const { response, isReview, currentAnswers, onEdit, isEdit, answerOptions } = props;
  const addressOptions: any = useMemo(() => {
    const addressOptions: any = answerOptions.addresses?.length
      ? [
          {
            label: 'Select a delivery address',
            optionIndex: 0,
            answerOptionsIndex: null,
            hideFromDropDown: true,
          },
        ]
      : [];
    const offset = answerOptions.addresses?.length ? 1 : 0;
    answerOptions?.addresses?.forEach((item: any, index: number) => {
      addressOptions.push({
        optionIndex: index + offset,
        answerOptionsIndex: index,
        label: (
          <>
            {item.use}
            <br />
            {item.line1} {item.line2 || ''}
            <br />
            {item.city}, {item.state}, {item.zip}
          </>
        ),
      });
    });
    if (addressOptions.length === 0) {
      addressOptions.push({
        answerOptionsIndex: -1,
        optionIndex: 0,
        label: STRINGS.add_new_address,
      });
    } else {
      addressOptions.push({
        answerOptionsIndex: -2,
        optionIndex: addressOptions.length,
        label: STRINGS.new_address,
      });
    }
    return addressOptions;
  }, [answerOptions.addresses]);

  const [selectedValue, setSelectedValue] = useState<any>(addressOptions[0]);

  const handleResponse = (value: any) => {
    const responseValue = { keyName, value };
    response(responseValue);
  };

  if (isReview) {
    return (
      <ReviewItem
        questionText={questionTextReview}
        currentAnswer={addressOptions[currentAnswers[keyName].optionIndex]?.label}
        onEdit={onEdit}
        keyName={keyName}
      />
    );
  }

  return (
    <Question
      questionText={questionText}
      response={handleResponse}
      keyName={keyName}
      isEdit={isEdit}
      options={{
        label: 'Address',
        items: addressOptions,
        setSelectedValue,
        selectedValue,
      }}
    />
  );
};

export default DeliveryAddress;
