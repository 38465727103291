import { useEffect, useState } from 'react';
import { CdmProgramClient } from 'clients/cdm-program';
import { logger } from '../../../winston-logger';

export const useCdmGroup = (patientId: number, therapyId: number | null, taskStatusId: number) => {
  const [therapyIds, setTherapyIds] = useState<number[]>([]);
  const [taskIds, setTaskIds] = useState<number[]>([]);

  useEffect(() => {
    const fetchAndSetCdmGroup = async () => {
      if (patientId && therapyId && taskStatusId) {
        try {
          const dcTasks = await CdmProgramClient.fetchDcTasksByTherapyId(
            patientId,
            therapyId,
            taskStatusId,
          );
          const allTherapyIds = new Set(dcTasks.data.map(t => t.therapy_id));
          setTherapyIds(Array.from(allTherapyIds));
          setTaskIds(dcTasks.data.map(t => t.id));
        } catch (error) {
          logger.error(error);
        }
      }
    };

    fetchAndSetCdmGroup();
  }, [patientId, therapyId, taskStatusId]);

  return {
    therapyIds,
    taskIds,
  };
};

export const useIcdGroup = (patientId: number, therapyId: number | null, taskStatusId: number) => {
  const [therapyIds, setTherapyIds] = useState<number[]>([]);
  const [taskIds, setTaskIds] = useState<number[]>([]);

  useEffect(() => {
    const fetchAndSetIcdGroup = async () => {
      if (patientId && therapyId && taskStatusId) {
        try {
          const dcTasks = await CdmProgramClient.fetchDcTasksByTherapyIdNoCdm(
            patientId,
            therapyId,
            taskStatusId,
          );
          const allTherapyIds = new Set(dcTasks.data.map(t => t.therapy_id));
          setTherapyIds(Array.from(allTherapyIds));
          setTaskIds(dcTasks.data.map(t => t.id));
        } catch (error) {
          logger.error(error);
        }
      }
    };

    fetchAndSetIcdGroup();
  }, [patientId, therapyId, taskStatusId]);

  return {
    therapyIds,
    taskIds,
  };
};
