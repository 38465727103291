export const styles = theme => ({
  noteContainer: {
    backgroundColor: theme.palette.primary.white,
    marginBottom: 1,
    borderRadius: 5,
    padding: 5,
    width: '98%',
    maxHeight: 105,
    overflow: 'hidden',
    cursor: 'pointer',
    position: 'relative',
    fontSize: theme.font.smallFontSize,
  },
  noteDocumentContainer: {
    backgroundColor: theme.palette.primary.white,
    marginBottom: 1,
    borderRadius: 5,
    padding: 10,
    maxHeight: 105,
    overflow: 'hidden',
    position: 'relative',
    fontSize: theme.font.smallFontSize,
  },
  firstNoteContainer: {
    backgroundColor: theme.palette.primary.white,
    borderTopRightRadius: 5,
    borderTopLeftRadius: 5,
    padding: 5,
    width: '98%',
    maxHeight: 105,
    overflow: 'hidden',
    cursor: 'pointer',
    position: 'relative',
    fontSize: theme.font.smallFontSize,
  },
  lastNoteContainer: {
    backgroundColor: theme.palette.primary.white,
    borderTop: '1px solid rgba(0, 0, 0, 0.23)',
    marginBottom: 1,
    borderBottomLeftRadius: 5,
    borderBottomRightRadius: 5,
    padding: 5,
    width: '98%',
    maxHeight: 105,
    overflow: 'hidden',
    cursor: 'pointer',
    position: 'relative',
    fontSize: theme.font.smallFontSize,
  },
  noteBar: {
    width: theme.spacing(75),
    marginLeft: 10,
    marginRight: 10,
  },
  noteTitle: {
    fontSize: theme.font.largeFontSize,
    fontWeight: 'bold',
    marginLeft: 5,
  },
  noteDate: {
    display: 'inline-block',
    fontSize: 11,
    paddingLeft: 5,
  },
  noteArchivedText: {
    display: 'inline-block',
    fontSize: 13,
    paddingLeft: 5,
    fontWeight: 'Bold',
    color: theme.palette.primary.trellisBlue,
  },
  noteCreator: {
    display: 'inline-block',
    fontSize: 13,
    color: theme.palette.primary.grey12,
    fontWeight: 'Bold',
  },
  noteBtn: {
    bottom: 3,
    marginRight: 5,
  },
  noteArchiveBtn: {
    bottom: 8,
    borderRadius: 25,
  },
  noteDisplay: {
    padding: 5,
  },
  noteMentionText: {
    backgroundColor: theme.palette.primary.bluesky,
    color: theme.palette.primary.deepBlue,
  },
  closeBtn: {
    bottom: 5,
  },
  alignRight: {
    textAlign: 'right',
  },
  moreNotesBtnContainer: {
    textAlign: 'center',
    cursor: 'pointer',
  },
  moreNotesBtn: {
    textAlign: 'center',
  },
  loadContainer: {
    position: 'relative',
    minHeight: 50,
  },
  loadMoreContainer: {
    position: 'relative',
    height: 50,
  },
  archivedNoteButtonContainer: {
    position: 'relative',
    height: 50,
    paddingTop: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  noteIconContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    marginRight: 5,
    height: '100%',
    position: 'absolute',
    right: 0,
    bottom: 5,
  },
  noteCommunicationIconContainer: {
    height: '12px',
  },
  addNewNoteDisplay: {
    padding: 2,
  },
  noteEntry: {
    whiteSpace: 'pre-wrap',
    wordBreak: 'break-word',
    overflow: 'hidden',
    display: 'inline',
  },
  dateContainer: {
    textAlign: 'right',
    marginRight: 10,
  },
  confirmationPanelContainer: {
    marginLeft: 5,
    marginRight: 5,
  },
  noteFieldContainer: {
    marginLeft: 5,
    marginRight: 5,
    marginTop: 5,
  },
  tag: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    bottom: 0,
  },
  communication: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    fontSize: 12,
  },
  communicationResolution: {
    color: theme.palette.primary.main,
    fontWeight: 'normal',
    fontSize: 12,
  },
  communicationResolutionWarning: {
    color: theme.palette.primary.main,
    fontWeight: 'normal',
    fontSize: 12,
  },
  pinnedNote: {
    borderLeft: '4px solid',
    borderColor: theme.palette.primary.important,
  },
  archivedNote: {
    backgroundColor: theme.palette.primary.archive.background,
    paddingLeft: 5,
  },
  pinButtonContainer: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  pinButton: {
    margin: 0,
    padding: 0,
    minHeight: 0,
    minWidth: 25,
    position: 'relative',
    top: -2,
    justifyContent: 'top',
    verticalAlign: 'top',
  },
  displayPinOffset: {
    top: -4,
  },
  communicationResult: {
    marginLeft: 3,
  },
  noteText: {
    marginLeft: 45,
  },
  communicationFormContainer: {
    width: 600,
    padding: 10,
  },
  communicationFormContainerMinimal: {
    height: 500,
  },
  full: {
    width: '100%',
  },
  communicationIconContainer: {
    position: 'relative',
    top: 3,
    marginRight: 5,
  },
  incidentIconContainer: {
    position: 'relative',
    marginRight: 5,
  },
  button: {
    margin: theme.spacing(1),
    marginLeft: 0,
  },
  communicationButton: {
    textTransform: 'none',
    marginTop: 5,
    marginRight: 0,
    paddingTop: 0,
    paddingBottom: 0,
  },
  interventionIconContainer: {
    position: 'relative',
    top: 3,
    marginRight: 5,
  },
  interventionButton: {
    textTransform: 'none',
    marginTop: 5,
    marginRight: 0,
    marginLeft: 0,
    paddingTop: 0,
    paddingBottom: 0,
  },
  interventionForm: {
    padding: theme.spacing(1),
    margin: theme.spacing(1),
    width: 800,
  },
  qreIncidentForm: {
    padding: theme.spacing(1),
    margin: theme.spacing(1),
    width: 800,
    overflow: 'auto',
    maxHeight: 600,
  },
  popoverWithOverflow: {
    overflowY: 'scroll',
    overflowX: 'visible',
  },
  incidentPopover: {
    overflowY: 'auto',
  },
  noteLabel: {
    fontSize: '13px',
    cursor: 'pointer',
  },
  followupBlock: {
    paddingLeft: 20,
  },
  followupDate: {
    color: theme.typography.title.color,
  },
  followupDateIcon: {
    color: theme.typography.title.color,
    height: '20px',
    marginLeft: '-10px',
    marginTop: '5px',
  },
  radioGroup: {
    flexDirection: 'row',
    padding: '6px 0 0',
  },
  radioGroupMinimal: {
    flexDirection: 'row',
  },
  inLabelIcon: {
    marginTop: '-8px',
  },
  communicationInlineIcon: {
    width: '18px',
    height: '18px',
    border: '1px',
    borderColor: theme.palette.primary.trellisBlue,
    color: theme.palette.primary.trellisBlue,
    paddingRight: theme.spacing(1),
    transform: 'translate(0px, -2px)',
  },
  communicationInlineIconDone: {
    width: '18px',
    height: '18px',
    borderColor: theme.palette.primary.cuttySark,
    border: '1px',
    color: theme.palette.primary.cuttySark,
    paddingLeft: theme.spacing(1),
    transform: 'translate(0px, 4px)',
  },
  communicationInlineIconWarning: {
    width: '18px',
    height: '18px',
    border: '1px',
    borderColor: theme.palette.primary.yellow2,
    fill: theme.palette.primary.yellow2,
    fontSize: 'large',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    transform: 'translate(0px, 4px)',
  },
  archiveReasonText: {
    display: 'inline-block',
    fontSize: 13,
    color: theme.palette.primary.steelGrey,
    fontWeight: 'Bold',
  },
  tagBar: {
    paddingTop: 3,
    paddingBottom: 3,
    height: '24px',
  },
  allNotesButtonText: {
    fontSize: 14,
    color: theme.palette.primary.trellisBlue,
    fontWeight: 'Bold',
  },
  noteArchivedTextTop: {
    fontSize: 14,
    fontWeight: 'Bold',
    color: theme.palette.primary.steelGrey,
  },
  contactPopover: {
    padding: theme.spacing(1),
  },
  communicationInlineIconDropdownArrow: {
    transform: 'translate(0px, 7px)',
  },

  communicationDropdownLong: {
    width: 188,
    transform: 'translate(0px, -2px)',
  },
  communicationDropdownShort: {
    width: 103,
    transform: 'translate(0px, -2px)',
  },
  communicationReasonRow: {
    paddingLeft: theme.spacing(1),
  },
  treeSwitchContainer: {
    paddingRight: theme.spacing(1),
  },
  treeViewSpacing: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(1),
  },
  noteInLineWithTasks: {
    paddingLeft: theme.spacing(1),
  },
  noteBarTop: {
    paddingTop: theme.spacing(2),
    width: theme.spacing(75),
    position: 'sticky',
    top: 0,
    zIndex: 9999,
    backgroundColor: theme.palette.primary.white,
    marginLeft: 10,
    marginRight: 10,
  },
  ellipsis: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textAlign: 'left',
    fontSize: 11,
  },
  patientNameDisplay: {
    paddingLeft: 5,
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    fontSize: 15,
  },
  noteSpacing: {
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
  },
  contactNoteText: {
    marginTop: theme.spacing(3),
  },
  contactNoteTextLvm: {
    marginTop: theme.spacing(5),
  },
  noteFilterContainer: {
    padding: 10,
  },
  noteFilterIcon: {
    paddingTop: 10,
    paddingLeft: 10,
    paddingRight: 10,
  },
  drawBorder: {
    fontSize: theme.font.smallFontSize,
    border: '1px solid rgba(0, 0, 0, 0.23)',
    borderRadius: 5,
    padding: 5,
    marginBottom: 2,
    overflow: 'hidden',
    maxHeight: 200,
    position: 'relative',
  },
  qreButton: {
    textTransform: 'none',
    marginTop: theme.spacing(0.5),
    marginRight: 0,
    marginLeft: 0,
    paddingTop: 0,
    paddingBottom: 0,
    minWidth: theme.spacing(15),
  },
  noteWrapper: {
    width: theme.spacing(78),
    padding: theme.spacing(2),
  },
  smsConversationWrapper: {
    padding: theme.spacing(2.5),
  },
});
