/* eslint-disable camelcase */
import React, { Component } from 'react';
import withStyles from '@mui/styles/withStyles';
import { Grid, CircularProgress, Backdrop, Card, CardContent, Typography } from '@mui/material';
import compose from 'recompose/compose';
import { reduxForm, formValueSelector, getFormValues, change } from 'redux-form';
import { connect } from 'react-redux';
import {
  addTaskOldValues,
  followupDateChanged,
  getDaysSupplyForTask,
} from 'services/utils/task-service';
import { translateUsers } from 'services/utils/users-service';
import { fetchResourceLink } from 'actions/action-resource-links';
import { workListChanged, fetchPatient } from 'actions/action-patient';
import { editFillCoordination, fetchTaskCounts, updateTasksProperties } from 'actions/action-tasks';
import ConfirmationPanel from 'components/form/confirmation/confirmation-panel';
import { addNewDocument } from 'actions/action-uploaded-documents';
import { notifyError } from 'actions/action-notifications';
import {
  dynamicFillDeliveryConfirmationStatusMap,
  dynamicFillCoordinationStatusMap,
} from 'constants/task-statuses';
import { getFullAddressList } from 'services/utils/demographic-service';
import { printPdfDocument } from 'containers/patient/documents/preview-document';
import { convertToArborDate } from 'models/time/arbor-date';
import { FillDeliveryConfirmationStatus } from 'interfaces/enums/TaskStatuses/FillDeliveryConfirmationStatus';
import { windowFeatureIsEnabled } from 'config/window-features';
import {
  EDIT_FC_SUCCESS,
  EDIT_FILL_COORDINATION_FORM,
  FA_CHART_REVIEW,
  FC,
  FDC,
} from '../../../constants';
import { styles } from '../task-detail-styles';
import FillCoordinationEditForm from './fill-coordination-edit-form';
import {
  buildButtonTexts,
  buildInitialValues,
  buildPayload,
  getSiblingTaskTherapies,
  getChangedField,
  calculateValueOnInputChange,
  updateWeatherBasedOnAddress,
  calculateTotalCopayAmount,
  otcProvider,
  getDefaultSideEffectsInt,
  buildNewInterventionsPayload,
} from './fc-util';
import { StatusMapUtil } from '../../../utils/status-map-util';
import { SideEffectType, EditComponentMode } from './types';
import { getLinkedFCTaskForId } from '../../patient/tasks-new/tasks-table/utils';

class EditFillCoordination extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoadingPreviewAfterSaving: false,
      newInterventions: [],
      taskArr: props.task ? [props.task] : [],
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleAddressChange = this.handleAddressChange.bind(this);
    this.changeNewInterventionsHandler = this.changeNewInterventionsHandler.bind(this);
    this.initNewInterventionsFromSideEffect = this.initNewInterventionsFromSideEffect.bind(this);
    this.initNewInterventionsFromMissedDose = this.initNewInterventionsFromMissedDose.bind(this);
    this.fillCoordinationStatusMap = dynamicFillCoordinationStatusMap();
  }

  setTaskArr(task) {
    this.setState({
      taskArr: [task],
    });
  }

  componentDidUpdate(prevProps) {
    const { change, formValues, task } = this.props;

    if (prevProps.formValues && formValues) {
      const taskIds = Object.keys(prevProps.formValues.on_hand_qty);
      taskIds.forEach(id => {
        const input = getChangedField(prevProps, formValues, id);
        calculateValueOnInputChange(input, change, id, formValues);
      });
    }

    if (prevProps.task?.id !== task?.id) {
      this.setTaskArr([task]);
    }

    this.handleAddressChange(prevProps);
    this.initNewInterventionsFromSideEffect(prevProps.formValues, formValues);
    this.initNewInterventionsFromMissedDose(prevProps.formValues, formValues);
  }

  // eslint-disable-next-line react/sort-comp
  handleAddressChange(prevProps) {
    const { change, formValues } = this.props; // eslint-disable-line no-shadow
    const prevAddress =
      prevProps && prevProps.formValues && prevProps.formValues.address
        ? prevProps.formValues.address
        : {};
    const prevShipDate =
      prevProps && prevProps.formValues && prevProps.formValues.ship_date
        ? prevProps.formValues.ship_date
        : '';
    if (
      formValues &&
      formValues.address &&
      formValues.address.value &&
      formValues.ship_date &&
      (formValues.address.value !== prevAddress.value || formValues.ship_date !== prevShipDate)
    ) {
      updateWeatherBasedOnAddress(formValues.address.label, formValues.ship_date, change);
    }
  }

  changeNewInterventionsHandler(newInterventions) {
    this.setState({
      newInterventions,
    });
  }

  initNewInterventionsFromSideEffect(prevFormValues, formValues) {
    if (prevFormValues && formValues && prevFormValues.side_effects !== formValues.side_effects) {
      const { newInterventions } = this.state;
      const missedDoseInterventions = newInterventions.filter(
        int => int.sideeffect_type === SideEffectType.MissedMedicationDose,
      );
      this.setState({
        newInterventions: [
          ...missedDoseInterventions,
          getDefaultSideEffectsInt(SideEffectType.Common),
        ],
      });
    }
  }

  initNewInterventionsFromMissedDose(prevFormValues, formValues) {
    if (
      prevFormValues &&
      formValues &&
      prevFormValues.patient_missed_doses !== formValues.patient_missed_doses
    ) {
      const { newInterventions } = this.state;
      const sideEffectInterventions = newInterventions.filter(
        int => int.sideeffect_type === SideEffectType.Common,
      );
      this.setState({
        newInterventions: [
          ...sideEffectInterventions,
          getDefaultSideEffectsInt(SideEffectType.MissedMedicationDose),
        ],
      });
    }
  }

  // Handles the submit functionality. Right now it sets some fields to null for simplicity's sake
  async handleSubmit(values) {
    const {
      cancel,
      contactList,
      editFillCoordination, // eslint-disable-line no-shadow
      fetchPatient, // eslint-disable-line no-shadow
      fetchTaskCounts, // eslint-disable-line no-shadow
      patient,
      selectedCustomerId,
      selectedPatientId,
      task,
      workListChanged, // eslint-disable-line no-shadow
      paymentMethodTypes,
      updateTasksProperties, // eslint-disable-line no-shadow
      fillCycles,
      getNextFCTask,
    } = this.props;

    const { newInterventions } = this.state;

    const method = values.preferred_rx_delivery_method
      ? values.preferred_rx_delivery_method.value
      : null;

    const allAddresses = getFullAddressList(patient, contactList);

    const primaryTaskPayload = buildPayload(
      values,
      task,
      selectedPatientId,
      method,
      false,
      task.status_id,
      paymentMethodTypes,
      allAddresses,
    );

    const senderFormData = addTaskOldValues(
      {
        ...primaryTaskPayload,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        new_interventions: buildNewInterventionsPayload(newInterventions),
      },
      task,
    );

    // Check if the task has days supply changed, and has an open linked task
    let nextLinkedFCUpdates = null;
    const taskDaysSupply = getDaysSupplyForTask(task, fillCycles);
    const daysSupplyChanged =
      taskDaysSupply && primaryTaskPayload.days_supply
        ? taskDaysSupply.toString() !== primaryTaskPayload.days_supply.toString()
        : false;
    if (daysSupplyChanged) {
      const nextLinkedFCTask = getNextFCTask(task.id);
      // If the next linked FC task is found, assemble an update payload for that FC
      if (nextLinkedFCTask) {
        const linkedTaskUpdatePayload = buildPayload(
          {},
          nextLinkedFCTask,
          selectedPatientId,
          method,
          false,
          nextLinkedFCTask.status_id,
          paymentMethodTypes,
          allAddresses,
        );
        // Don't include empty keys in the request
        Object.keys(linkedTaskUpdatePayload).forEach(key => {
          if (
            typeof linkedTaskUpdatePayload[key] === 'undefined' ||
            linkedTaskUpdatePayload[key] === null
          ) {
            delete linkedTaskUpdatePayload[key];
          }
        });
        // Get payload values for needsby_date from parent task's next_needsby_date
        const nextNeedsbyDate = values.next_needsby_date?.[task.id];
        const needsbyDatePayload = convertToArborDate(nextNeedsbyDate, true).getUtcDate();
        linkedTaskUpdatePayload.needsby_date = needsbyDatePayload;
        linkedTaskUpdatePayload.old_status_id = nextLinkedFCTask.status_id;
        nextLinkedFCUpdates = { ...nextLinkedFCTask, ...linkedTaskUpdatePayload };
      }
    }

    const response = await editFillCoordination({
      ...senderFormData,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      old_delivery_dt: convertToArborDate(senderFormData.old_delivery_dt).getUtcDate(),
    });

    const updatedTasks = response?.payload?.data?.updated_task;

    // If we have updates to make for a linked FC, submit the updates
    if (nextLinkedFCUpdates) {
      const linkedFCUpdateResponse = await editFillCoordination(nextLinkedFCUpdates);
      const nextLinkedTaskUpdated = linkedFCUpdateResponse.payload?.data?.updated_task?.[0];
      updatedTasks.push(nextLinkedTaskUpdated);
    }

    const updatedFCsInSameOrder = updatedTasks?.reduce(
      (upTasks, upTask) =>
        upTask.taskType === FC && upTask.id !== task.id
          ? [...upTasks, { ...upTask, taskId: upTask.id }]
          : upTasks,
      [],
    );
    const createdFDC = updatedTasks?.reduce(
      (upTasks, upTask) =>
        upTask.taskType === FDC ? [...upTasks, { ...upTask, taskId: upTask.id }] : upTasks,
      [],
    );
    await updateTasksProperties(updatedFCsInSameOrder);
    await updateTasksProperties(createdFDC);
    const orderFormDocumentId = createdFDC[0]?.orderFormDocumentId;
    if (orderFormDocumentId != null) {
      await printPdfDocument(orderFormDocumentId, selectedCustomerId, selectedPatientId);
    }
    this.props.fetchFillCycles({
      selectedPatientId,
      selectedTherapyId: updatedTasks[0].therapy_id,
    });

    const fcChecklistHasYes =
      primaryTaskPayload.new_allergies ||
      primaryTaskPayload.changes_in_medication ||
      primaryTaskPayload.new_medical_conditions ||
      primaryTaskPayload.hospital_visit ||
      primaryTaskPayload.new_infections ||
      primaryTaskPayload.side_effects ||
      primaryTaskPayload.patient_questions ||
      primaryTaskPayload.patient_missed_doses ||
      primaryTaskPayload.signature_required ||
      primaryTaskPayload.wk_pp_received;

    if (followupDateChanged(task.followup_dt, primaryTaskPayload.followup_dt)) {
      workListChanged();
      fetchTaskCounts();
    }
    if (fcChecklistHasYes) {
      workListChanged();
      fetchTaskCounts();
    }
    await fetchPatient(selectedPatientId);

    return cancel();
  }

  render() {
    const {
      cancel,
      change,
      classes,
      contactList,
      deliveryMethodVal,
      form,
      formValues,
      handleSubmit,
      patient,
      pristine,
      selectedStatus,
      siblingTaskTherapies,
      statuses,
      submitting,
      task,
      therapies,
      therapy,
      users,
    } = this.props;

    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { fdc_status_id: FDCStatusId } = task;

    const isShipped =
      FDCStatusId &&
      ![FillDeliveryConfirmationStatus.Dispense, FillDeliveryConfirmationStatus.Packed].includes(
        FDCStatusId,
      );
    const { isLoadingPreviewAfterSaving } = this.state;

    const methodIsChartReview = formValues && formValues.method === FA_CHART_REVIEW;

    const changeAncillarySupplies = value => {
      // eslint-disable-next-line no-shadow
      const { change } = this.props;
      change('ancillary_supplies', value);
    };
    // eslint-disable-next-line arrow-body-style
    const renderNewForm = () => {
      const { newInterventions, taskArr } = this.state;
      const userData = users && users.length > 0 && translateUsers(users);
      const copayAmountList = Object.values(formValues?.copay_amount || {});

      const selectedStatusId =
        StatusMapUtil.getStatusIdFromStatusName(selectedStatus, this.fillCoordinationStatusMap) ||
        0;

      const fdcStatusMap = dynamicFillDeliveryConfirmationStatusMap();

      return (
        <Grid container>
          <FillCoordinationEditForm
            caller="edit-fill-coordination.js"
            change={change}
            classes={classes}
            contactList={contactList}
            deliveryMethodVal={deliveryMethodVal}
            totalCopayAmount={calculateTotalCopayAmount(copayAmountList)}
            methodIsChartReview={methodIsChartReview}
            otcProvider={otcProvider}
            patient={patient}
            selectedStatus={selectedStatusId}
            statuses={statuses.filter(s => s.reason !== null)}
            tasks={taskArr}
            allTasks={taskArr}
            therapies={therapies}
            therapy={therapy}
            userData={userData}
            users={users}
            removedFCIds={[]}
            componentMode={EditComponentMode.Edit}
            siblingTasks={siblingTaskTherapies}
            formName={form}
            haveNewAllergies={Boolean(formValues?.new_allergies)}
            haveNewInfections={Boolean(formValues?.new_infections)}
            selectedAncillarySupplies={formValues?.ancillary_supplies}
            changeAncillarySupplies={changeAncillarySupplies}
            areMedicationChanges={Boolean(formValues?.changes_in_medication)}
            haveNewSideEffects={Boolean(formValues?.side_effects)}
            changeNewInterventionsHandler={this.changeNewInterventionsHandler}
            newInterventions={newInterventions}
            patientMissedDoses={Boolean(formValues?.patient_missed_doses)}
            paymentMethodOnFileSelected={Boolean(formValues?.payment_method_on_file)}
            statusReason={
              formValues && formValues.status_id
                ? statuses.find(s => s.id === formValues.status_id).reason
                : ''
            }
            FDCStatusReason={(FDCStatusId && fdcStatusMap[FDCStatusId].status) || undefined}
            upsPackagingTypeSelected={
              formValues?.ups_packaging_type ?? { label: 'Customer Supplied Package', value: 2 }
            }
          />
        </Grid>
      );
    };

    // eslint-disable-next-line arrow-body-style
    const buttonTexts = () => {
      return buildButtonTexts(selectedStatus);
    };

    return (
      <Grid
        container
        justifyContent="flex-start"
        className={(classes.formRowContainer, classes.formContainer)}
      >
        <Grid item xs={12}>
          <Backdrop className={classes.backdrop} open={isLoadingPreviewAfterSaving}>
            <Card>
              <CardContent>
                <Grid container spacing={1}>
                  <Grid item xs={8}>
                    <Typography variant="h6">Loading Document Preview</Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <CircularProgress
                      data-qa-id="loading-preview-after-saving"
                      className={classes.loading}
                      size={50}
                      variant="indeterminate"
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Backdrop>
          <form
            onSubmit={handleSubmit(this.handleSubmit)}
            className={classes.editFormContainer}
            autoComplete="off"
          >
            {renderNewForm()}
            {!windowFeatureIsEnabled('edit_fc') && isShipped && (
              <Typography className={classes.alertIsShipped}>
                This package has already been shipped.
              </Typography>
            )}
            {/* Eighth Row */}
            <ConfirmationPanel
              cancelButtonName="edit_therapy_cancel_button"
              submitButtonName="edit_therapy_submit_button"
              submitButtonText={buttonTexts().save}
              isLoading={submitting}
              handleCancel={cancel}
              disableSubmit={
                pristine || (!windowFeatureIsEnabled('edit_fc') && isShipped) || submitting
              }
              buttonIdPrefix={`${task.therapy_id}_edit_FC_${task.id}`}
            />
          </form>
        </Grid>
      </Grid>
    );
  }
}

function handleFormName(task) {
  if (!task) {
    return null;
  }
  const name = `${EDIT_FILL_COORDINATION_FORM}_${task.therapy_id}_${task.id}`;
  return name;
}

function mapStateToProps(state, props) {
  const {
    patient,
    contactList,
    selectedPatientId,
    fillCycles,
    therapies,
    uploadedDocuments,
    tasks,
    lookups: { paymentMethodTypes },
  } = state;
  const therapy = state.therapies.data && therapies.data[props.task.therapy_id];
  const name = handleFormName(props.task);
  const selector = formValueSelector(name);
  const upsPackagingTypeOptions = state.lookups.upsPackagingType;
  const preferredRxDeliveryPharmacyId = state?.patient?.preferred_rx_delivery_pharmacy_id;
  const preferredRxDeliveryPharmacy = state?.pharmacies?.pharmacies.find(
    pharm => pharm.id === preferredRxDeliveryPharmacyId,
  );
  const internalPharmacies = state?.pharmacies?.pharmacies;
  const selectedPatientQuestions = selector(state, 'patient_questions');
  const selectedPatientMissedDoses = selector(state, 'patient_missed_doses');
  const selectedSignatureRequired = selector(state, 'signature_required');
  const selectedNewAllergies = selector(state, 'new_allergies');
  const selectedNewInfections = selector(state, 'new_infections');
  const selectedChangesInMedication = selector(state, 'changes_in_medication');
  const selectedNewMedicalConditions = selector(state, 'new_medical_conditions');
  const selectedHospitalVisit = selector(state, 'hospital_visit');
  const selectedSideEffects = selector(state, 'side_effects');
  const deliveryMethodVal = selector(state, 'preferred_rx_delivery_method');
  const checklistChanged = !!(
    selectedPatientQuestions ||
    selectedPatientMissedDoses ||
    selectedSignatureRequired ||
    selectedNewAllergies ||
    selectedNewInfections ||
    selectedChangesInMedication ||
    selectedChangesInMedication ||
    selectedNewMedicalConditions ||
    selectedHospitalVisit ||
    selectedSideEffects
  );

  const siblingTaskTherapies = getSiblingTaskTherapies(props.task, tasks.data, therapies.data);

  const initialValues = buildInitialValues(
    [props.task],
    props,
    patient,
    { [therapy.id]: therapy },
    contactList,
    state.fillCycles,
    siblingTaskTherapies,
    upsPackagingTypeOptions,
    preferredRxDeliveryPharmacy,
    internalPharmacies,
  );

  const returnObj = {
    checklistChanged,
    contactList,
    deliveryMethodVal,
    documents: uploadedDocuments.documents || [],
    fillCycles,
    form: name,
    formValues: getFormValues(name)(state),
    initialValues,
    patient,
    selectedCustomerId: state.filters.selectedCustomerId,
    selectedPatientId,
    state,
    therapies: therapies.data,
    therapy,
    user: state.auth.currentUser,
    siblingTaskTherapies,
    paymentMethodTypes,
    getNextFCTask: getLinkedFCTaskForId(state),
  };
  return returnObj;
}

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    change,
    updateTasksProperties,
    addNewDocument,
    editFillCoordination,
    fetchPatient,
    fetchResourceLink,
    fetchTaskCounts,
    notifyError,
    workListChanged,
    fetchFillCycles: patientAndTherapy => ({
      type: EDIT_FC_SUCCESS,
      payload: patientAndTherapy,
    }),
  }),
)(reduxForm({})(EditFillCoordination));
