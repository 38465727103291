import {
  UPDATE_ELIGIBILITY_CHECK,
  FETCH_ELIGIBILITY_CHECK_HISTORY,
  CLOSE_ELIGIBILITY_CHECK,
  UPDATE_PATIENT_INSURANCES,
} from 'constants/index';
import HTTP from '../services/http';

export const updateEligibilityCheck = state => ({
  type: UPDATE_ELIGIBILITY_CHECK,
  payload: state,
});

export const closeEligibilityCheck = state => ({
  type: CLOSE_ELIGIBILITY_CHECK,
  payload: state,
});

export const fetchEligibilityCheckHistory = patientId => {
  const request = HTTP.get(`/patients/${patientId}/eligibility-check/history`, {});

  return {
    type: FETCH_ELIGIBILITY_CHECK_HISTORY,
    payload: request,
    meta: { patientId },
  };
};

export const autoUpsertPatientPbmInsurance = patientId => {
  const request = HTTP.post(`/patients/${patientId}/eligibility-check/upsert`);
  return {
    type: UPDATE_PATIENT_INSURANCES,
    payload: request,
    meta: { patientId },
  };
};
