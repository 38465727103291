import React from 'react';
import { Grid, Button } from '@mui/material';
import { useDispatch } from 'react-redux';
import { integrateUpdatedTasks } from 'actions/action-tasks';
import { fetchNotes } from 'actions/action-notes';
import { notifySuccess } from 'actions/action-notifications';
import { FillCoordinationClient } from '../../../../../clients/fill-coordination-client';

const ResetToRequiredStateForm = ({ tasks, smsPhoneNumber, patientId }: any) => {
  const dispatch = useDispatch();

  const moveToRequired = async () => {
    const response = await FillCoordinationClient.moveToRequiredState({ tasks });
    dispatch(integrateUpdatedTasks(response));
  };

  const moveToRequiredAndSendSecureLink = async () => {
    const response = await FillCoordinationClient.moveToRequiredStateAndSendSecureLink({
      tasks,
      smsPhoneNumber,
      patientId,
    });
    dispatch(integrateUpdatedTasks(response));
    dispatch(fetchNotes({ patient_id: patientId }));
    dispatch(notifySuccess('Secure link was sent successfully'));
  };

  return (
    <Grid
      container
      style={{
        height: '75px',
        display: 'flex',
        alignContent: 'center',
        justifyContent: 'space-around',
      }}
    >
      <Grid item>
        <Grid>
          <Button variant="contained" onClick={moveToRequiredAndSendSecureLink}>
            {`Reset task${tasks.length > 1 ? 's' : ''} to Required State and Send New Secure Link`}
          </Button>
        </Grid>
      </Grid>
      <Grid item>
        <Grid>
          <Button variant="contained" onClick={moveToRequired}>
            {`Reset task${tasks.length > 1 ? 's' : ''} to Required State`}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ResetToRequiredStateForm;
