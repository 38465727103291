import { Button, Typography, Tooltip, Box } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import classNames from 'classnames';
import { EditAdd, EditPencilMd, TrellisLogo, PillIcon } from 'components/icons/icons';
import React, { useMemo } from 'react';
import boolean from 'boolean';
import { buildQaId } from 'utils/build-qa-id';
import { MedUtils } from 'utils/med-utils';
import { styles } from '../patient-clinical-styles';

const qaIdBuilder = buildQaId('dosing-regimen', '.');

function MedicationLink(props) {
  const {
    medication,
    classes,
    disabled = false,
    type,
    toggleTherapyHandler,
    toggleAddTherapyHandler,
    id,
    group,
    displayPill,
  } = props;
  const {
    drug_name: drugName,
    is_brand_med: isBrandDrug,
    generic_name: genericName,
    specialty_type: specialtyType,
    therapy,
  } = medication;

  const medName = useMemo(
    () => (boolean(isBrandDrug) && genericName ? `${drugName} (${genericName})` : drugName),
    [isBrandDrug, genericName, drugName],
  );

  if (type === 'actionIcon') {
    if (therapy) {
      return (
        <Button onClick={() => toggleTherapyHandler(therapy)} id={id}>
          <EditPencilMd />
        </Button>
      );
    }
    return (
      <Button onClick={() => toggleAddTherapyHandler(medication)} id={id}>
        <EditAdd />
      </Button>
    );
  }

  const tags = [];
  const medicationTags = [];
  if (therapy) {
    tags.push(...MedUtils.getMedicationTags(therapy.specialty_type));
    if (therapy.one_time_fill) {
      tags.push({ text: 'OTF', description: 'One-Time Fill' });
    }
  }

  if (!medication.verified_dt) {
    medicationTags.push({
      text: 'NEW',
      description: 'NEW',
    });

    if (specialtyType) {
      medicationTags.push(...MedUtils.getMedicationTags(undefined, specialtyType));
    }
  }

  // eslint-disable-next-line max-len
  const ariaLabel = `open ${therapy ? 'therapy' : 'medication'} ${group ? 'group ' : ''}${
    medication.ndc
  }`;

  // eslint-disable-next-line camelcase
  const dosisRegimen = therapy?.dosis_regimen;

  return (
    <>
      {therapy ? (
        <>
          <Button
            className={classes.medicationLink}
            onClick={() => toggleTherapyHandler && toggleTherapyHandler(therapy)}
            id={id}
            aria-label={ariaLabel}
          >
            <Typography variant="h6" className={classes.medicationLinkLabel}>
              {medName}
            </Typography>
            {tags
              .filter(tag => !!tag.text)
              .map(tag => (
                <Tooltip title={tag.description} key={tag.text}>
                  <Typography
                    component="span"
                    key="is_specialty"
                    className={classNames(classes.specialtyTag, tag.text)}
                  >
                    {tag.text}
                  </Typography>
                </Tooltip>
              ))}
            {/* Show trellis icon when therapy is PRE-THERAPY or OPT-IN dispensing service */}
            {medication.showIcon && (
              <Tooltip title="Managed by Trellis">
                <Box>
                  <TrellisLogo
                    style={{ marginTop: 5 }}
                    width={22}
                    height={22}
                    onClick={e => e.preventDefault()}
                  />
                </Box>
              </Tooltip>
            )}
            {displayPill && dosisRegimen ? (
              <div className={classes.medicationLinkPillIcon} data-qa-id={qaIdBuilder('pill')}>
                <PillIcon therapy={therapy} />
              </div>
            ) : null}
          </Button>
          {displayPill ? null : (
            <Typography className={classes.dosisRegimenLabel} data-qa-id={qaIdBuilder('text')}>
              {therapy.dosis_regimen}
            </Typography>
          )}
        </>
      ) : (
        <Button
          className={classes.medicationLink}
          onClick={() =>
            !disabled && toggleAddTherapyHandler && toggleAddTherapyHandler(medication)
          }
          id={id}
          aria-label={ariaLabel}
        >
          <Typography className={classes.medicationNameLabel}>{medName}</Typography>
          {medicationTags.map(tag => (
            <Tooltip title={tag.description} key={tag.text}>
              <Typography
                component="span"
                key="is_specialty"
                className={classNames(classes.specialtyTag, tag.text)}
              >
                {tag.text}
              </Typography>
            </Tooltip>
          ))}
        </Button>
      )}
    </>
  );
}

export default withStyles(styles, { withTheme: true })(MedicationLink);
