import React from 'react';
import { Box, Button, CircularProgress, Typography } from '@mui/material';
import TasksCancelForm from 'components/dynamic-form/therapy-forms/tasks-cancel-form';
import { IPatient } from 'interfaces/redux/IPatient';
import { ITherapy } from 'interfaces/redux/ITherapies';
import { formatPatientName } from 'services/utils/demographic-service';

interface PatientDischargeButtonProps {
  verifyValue: { name: string };
  tasksCancel: boolean;
  patient: IPatient;
  activeTherapies: ITherapy[];
  handleDischargePatient(): void;
  setTasksCancel(value: boolean): void;
  dischargeButtonLabel: string;
  onSubmit?(): void;
  isLoading: boolean;
}

export const PatientDischargeButton: React.FC<PatientDischargeButtonProps> = ({
  verifyValue,
  tasksCancel,
  patient,
  activeTherapies,
  handleDischargePatient,
  setTasksCancel,
  dischargeButtonLabel,
  onSubmit,
  isLoading,
}) => {
  const handleOnClick = () => {
    if (onSubmit) {
      onSubmit();
    } else {
      setTasksCancel(true);
    }
  };
  return (
    <>
      {tasksCancel && (
        <TasksCancelForm
          title={`Are you sure you want to discharge from ${verifyValue.name}?`}
          instructions={
            <Typography>
              <Box mb={2}>
                {'You are discharging '}
                <strong>{formatPatientName(patient)}</strong>
                {' from '}
                <strong>{verifyValue.name}</strong>
                <span>.</span>
              </Box>
              <Box>Below, you can also select which tasks you want to cancel.</Box>
            </Typography>
          }
          therapyIds={activeTherapies.map(t => t.id)}
          onClose={() => {
            setTasksCancel(false);
          }}
          reason="Clinic Discharged"
          onSubmit={handleDischargePatient}
          submitButtonText="Discharge and cancel tasks"
          filterTasks={undefined}
          cancelButtonText={undefined}
        />
      )}
      {isLoading ?
        <CircularProgress
          data-qa-id="loading"
          size={20}
        />
        :
        (
          <Button
            onClick={() => handleOnClick()}
            variant="outlined"
            sx={{
              backgroundColor: 'white',
              color: 'darkRed',
            }}
          >
            {dischargeButtonLabel}
          </Button>
        )}
    </>
  );
};

PatientDischargeButton.defaultProps = {
  onSubmit: undefined,
};
