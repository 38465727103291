import React from 'react';
import { Box, Grid, Button, Typography } from '@mui/material';
import { STRINGS } from '../../fc_ss_constants';

const ReviewItem = ({
  questionText,
  currentAnswer,
  onEdit,
  classes,
  keyName,
  children = <></>,
}) => {
  return (
    <Grid container item spacing={2} alignItems="center">
      <Grid item>
        {questionText}
        {children}
      </Grid>
      <Grid container item xs={12}>
        <Grid item xs="auto" className={classes.boxAnswerWrapper}>
          {keyName !== 'therapies' ? (
            <Box
              component={Typography}
              className={classes.boxAnswer}
              data-qa-id={`answer-${keyName}`}
            >
              {currentAnswer === STRINGS.answer_required ? (
                <span className={classes.required}>Answer Required</span>
              ) : (
                currentAnswer
              )}
            </Box>
          ) : null}
        </Grid>
        <Grid item xs>
          <Button
            className={classes.editButton}
            onClick={() => onEdit()}
            data-qa-id={`edit-${keyName}`}
          >
            Edit
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ReviewItem;
