import { ITask } from 'interfaces/redux/ITasks';
import { INT } from 'constants/index';
import { IIntPairs } from './types';

export class GroupedTaskTabUtil {
  static getAssociatedIntTask = (
    task: ITask,
    intPairs: IIntPairs[],
    tasksList: Record<string, ITask>,
  ): ITask | null => {
    if (intPairs.length) {
      for (const it of intPairs) {
        if (it.taskType === task.taskType && it.taskTypeId === task.id) {
          const tempTask = tasksList[`${INT}${it.intId}`];
          if (tempTask && Object.keys(tempTask).length) {
            return tempTask;
          }
        }
      }
    }
    return null;
  };
}
