import { useSelector } from 'react-redux';
import { ICounselingTask } from 'interfaces/redux/task-types/ICounselingTask';
import { IState } from 'interfaces/redux/IState';
import { IDataCollectTask } from 'interfaces/redux/task-types/IDataCollectTask';
import { convertToArborDate } from 'models/time/arbor-date';
import { useLatestDcs } from './use-latest-dcs';
import { IObjectiveDataCollect } from '../documentation/interfaces/IProps';

const useDocObjectiveDcs = (
  taskQty: number,
  currentSelectedTasks: ICounselingTask[],
): IObjectiveDataCollect[] => {
  const therapies = useSelector((state: IState) => state.therapies);
  const parsedItemsDcTasks = useLatestDcs(taskQty, currentSelectedTasks);

  return currentSelectedTasks.reduce(
    (objectiveDataCollects: IObjectiveDataCollect[], currentTask: ICounselingTask) => {
      const sortedByCreatedTasks = parsedItemsDcTasks.filter(
        parsedDcTask => currentTask.therapy_id === parsedDcTask.therapy_id,
      );

      const perTaskObjectiveDataCollects = sortedByCreatedTasks.reduce(
        (accum: IObjectiveDataCollect[], dcTask: IDataCollectTask) => {
          const taskId = dcTask.id;
          const drugName = therapies.data[dcTask.therapy_id]?.drug_name ?? '-';

          const reviewedDate = dcTask.reviewed_dt
            ? convertToArborDate(dcTask.reviewed_dt, true).getCustomerDate(true)
            : '';

          return [
            ...accum,
            {
              taskId,
              drugName,
              reviewedDate: reviewedDate || '',
              labs:
                dcTask.collectedItems?.filter(item => item.value != null && item.value !== '-') ||
                [],
            },
          ];
        },
        [],
      );

      return [...objectiveDataCollects, ...perTaskObjectiveDataCollects];
    },
    [],
  );
};

export { useDocObjectiveDcs };
