import {
  ADD_PATIENT,
  CLEAR_WORK_LIST,
  DISCHARGE_PATIENT,
  EDIT_PATIENT,
  FETCH_ORDERS,
  MARK_PATIENT_DECEASED,
  NEED_PATIENT_REFRESH,
  PATIENT_CHECKLIST_FETCH,
  REENROLL_PATIENT,
  REFRESH_THERAPIES_AND_TASKS,
  SEARCH_WORK_LIST,
  SELECT_PATIENT_TAB,
  SELECT_PATIENT_TASK,
  SELECT_PATIENT_TASK_FORCED,
  SEND_PATIENT_OPT_IN_SMS,
  SET_WORK_LIST,
  TOGGLE_TAB_REFRESH,
  UPDATE_PATIENT_PREFERENCE_REQUEST,
  UPDATE_PATIENT_SEARCH_INDEX,
  DATA_THERAPY_ARCHIVED_TASKS,
  DATA_PATIENT_ARCHIVED_TASKS,
  TASK_STATUS_DONE,
  TASK_STATUS_ARCHIVED,
  USER_PREFERENCES_SELECT_RECENT_PATIENT,
  WORK_LIST_CHANGED,
  FETCH_PATIENT_PROBLEMS,
  FETCH_PATIENT_PROBLEMS_SUCCESS,
  FETCH_PATIENT_PROBLEMS_FAILED,
  ADD_PROBLEM_SUCCESS,
  EDIT_PROBLEM_SUCCESS,
  UPDATE_PROBLEMS_VERIFICATION_STATUS,
  EDIT_PROBLEM_THERAPIES_ENROLLMENT_SUCCESS,
  SEND_SMS_TO_PATIENT_SUCCESS,
  FETCH_PATIENT_SMS_MESSAGES_SUCCESS,
  UPDATE_SMS_MESSAGE_TAGS_SUCCESS,
  MARK_SMS_AS_READ_SUCCESS,
  MARK_SMS_AS_UNREAD_SUCCESS,
  FETCH_PATIENT_ADHERENCE_DATA,
  FETCH_PATIENT_ADHERENCE_HISTORY,
  CLEAR_PATIENT_ADHERENCE_DATA,
  FETCH_PATIENT_ADHERENCE_COMPLIANCE_STATUS,
  REPORT_DUPLICATE_PATIENT_PROFILES,
  UPDATE_PATIENT_PROPERTIES,
  REFRESH_PATIENT_PHONES,
  FETCH_HIDDEN_QUESTIONS,
  FETCH_BI_INFO,
} from 'constants/index';
import HTTP from '../services/http';

export function fetchPatient(patientId, reload) {
  return {
    type: NEED_PATIENT_REFRESH,
    payload: { patientId, reload },
  };
}

export function fetchTherapyArchivedTasks(patientId, therapyId) {
  // eslint-disable-next-line max-len
  const url = `patients/${patientId}/therapies/${therapyId}/tasks?categories=${TASK_STATUS_DONE},${TASK_STATUS_ARCHIVED}`;

  const request = HTTP.get(url, {});
  return {
    type: DATA_THERAPY_ARCHIVED_TASKS,
    payload: request,
  };
}

export function fetchPatientArchivedTasks(
  patientId,
  archivedOnly = false,
  taskTypes = null,
  ignoreTherapyLevel = false,
) {
  const categories = archivedOnly
    ? `${TASK_STATUS_ARCHIVED}`
    : `${TASK_STATUS_DONE},${TASK_STATUS_ARCHIVED}`;
  let url = `patients/${patientId}/tasks?categories=${categories}`;

  if (taskTypes && taskTypes.length) {
    url = `${url}&task_types=${taskTypes.join(',')}`;
  }

  if (ignoreTherapyLevel) {
    url = `${url}&ignore_therapy_level=1`;
  }

  const request = HTTP.get(url, {});
  return {
    type: DATA_PATIENT_ARCHIVED_TASKS,
    payload: request,
  };
}

export function selectPatientTaskForced(taskId, therapyId, patientId) {
  return {
    type: SELECT_PATIENT_TASK_FORCED,
    payload: { taskId, therapyId, patientId },
  };
}

export function fetchHiddenQuestions() {
  return {
    type: FETCH_HIDDEN_QUESTIONS,
  };
}

export function selectPatientTask(taskId, therapyId, patientId) {
  return {
    type: SELECT_PATIENT_TASK,
    payload: {
      taskId: taskId ? Number(taskId) : null,
      therapyId: therapyId ? Number(therapyId) : null,
      patientId: patientId ? Number(patientId) : null,
    },
  };
}

export function updateWorkList(patientList) {
  return {
    type: SET_WORK_LIST,
    data: patientList,
  };
}

export function workListChanged() {
  return { type: WORK_LIST_CHANGED, skipAutoSelect: true };
}

export function searchPatients(criteria) {
  return {
    type: SEARCH_WORK_LIST,
    payload: criteria,
  };
}

export function updatePatientSearchIndex(newIndex) {
  return {
    type: UPDATE_PATIENT_SEARCH_INDEX,
    payload: newIndex,
  };
}

export function clearWorkList() {
  return {
    type: CLEAR_WORK_LIST,
  };
}

export function checkDuplicte(newPatient) {
  const postURL = '/patients/_duplicates';
  const request = HTTP.post(postURL, newPatient, {});
  return request;
}

export function addPatient(newPatient) {
  const postURL = '/patients/';
  const request = HTTP.post(postURL, newPatient, {});
  return {
    type: ADD_PATIENT,
    payload: request,
    meta: {
      errorMessage: 'Patient Add Failed: ',
      successMessage: 'Patient Added Successfully',
    },
  };
}

export function editPatient(updatedPatient) {
  const postURL = `/patients/${updatedPatient.id}`;
  const request = HTTP.patch(postURL, updatedPatient, {});
  return {
    type: EDIT_PATIENT,
    payload: request,
    meta: {
      errorMessage: 'Patient Updated Failed: ',
      successMessage: 'Patient Updated Successfully',
    },
  };
}

export function selectPatientTab(patientTab) {
  return {
    type: SELECT_PATIENT_TAB,
    payload: patientTab,
  };
}

export function getChecklistData(patientId) {
  return {
    type: PATIENT_CHECKLIST_FETCH,
    payload: {
      patientId,
    },
  };
}

export function toggleTabRefresh(refresh) {
  return {
    type: TOGGLE_TAB_REFRESH,
    payload: refresh,
  };
}

export function dischargePatient(patientId, clinic) {
  const postURL = `/patients/${patientId}/clinicId/${clinic.id}/discharge/`;
  const request = HTTP.patch(postURL, {}, {});
  return {
    type: DISCHARGE_PATIENT,
    payload: request,
    meta: {
      errorMessage: 'Patient Discharge Failed: ',
      successMessage: 'Patient Discharged Successfully',
    },
  };
}

export const reenrollPatient = (clinicId, clinicName) => ({
  type: REENROLL_PATIENT,
  payload: {
    clinicId,
    clinicName,
  },
});

export function markPatientAsDeceased(patientId) {
  const postURL = `patients/${patientId}/_mark-deceased`;
  const request = HTTP.patch(postURL);
  return {
    type: MARK_PATIENT_DECEASED,
    payload: request,
    meta: {
      errorMessage: 'Patient marked deceased Failed: ',
      successMessage: 'Patient marked deceased Successfully',
    },
  };
}

export function fetchOrders(patientId) {
  return {
    type: FETCH_ORDERS,
    payload: {
      patientId,
    },
  };
}

export function updatePatientPreferences(
  patientPreferences,
  patient,
  preferredContact,
  preferredContactItem,
) {
  return {
    type: UPDATE_PATIENT_PREFERENCE_REQUEST,
    payload: patientPreferences,
    meta: {
      patient,
      preferredContact,
      preferredContactItem,
    },
  };
}

export function refreshPatientTherapiesAndTasks(patientId) {
  return {
    type: REFRESH_THERAPIES_AND_TASKS,
    payload: {
      patientId,
    },
  };
}

export function recentPatientSelected(patientId) {
  return {
    type: USER_PREFERENCES_SELECT_RECENT_PATIENT,
    payload: {
      patientId,
    },
  };
}

export function sendPatientOptInSms(payload) {
  const request = HTTP.post(`/patients/${payload.patientId}/opt_in_message/`, payload, {});
  return {
    type: SEND_PATIENT_OPT_IN_SMS,
    payload: request,
    meta: {
      errorMessage: 'Patient SMS sent Failed: ',
      successMessage: 'Patient SMS sent Successfully',
    },
  };
}

export function fetchProblems(patientId) {
  return {
    type: FETCH_PATIENT_PROBLEMS,
    payload: {
      patientId,
    },
  };
}

export function fetchProblemsSuccess(problems) {
  return {
    type: FETCH_PATIENT_PROBLEMS_SUCCESS,
    payload: {
      problems,
    },
  };
}

export function fetchProblemsFailed() {
  return {
    type: FETCH_PATIENT_PROBLEMS_FAILED,
  };
}

export function addProblemSuccess(problem) {
  return {
    type: ADD_PROBLEM_SUCCESS,
    payload: {
      problem,
    },
  };
}

export function editProblemSuccess(problem, shouldUnverifyProblemsList) {
  return {
    type: EDIT_PROBLEM_SUCCESS,
    payload: {
      problem,
      shouldUnverifyProblemsList,
    },
  };
}

export function updateProblemsVerificationStatus(verified, verifiedBy, verifiedDateTime) {
  return {
    type: UPDATE_PROBLEMS_VERIFICATION_STATUS,
    payload: {
      verified,
      verifiedBy,
      verifiedDateTime,
    },
  };
}

export function editProblemTherapiesEnrollmentSuccess(updatedTasks) {
  return {
    type: EDIT_PROBLEM_THERAPIES_ENROLLMENT_SUCCESS,
    payload: {
      data: {
        updatedTasks,
      },
    },
  };
}

export function fetchSmsMessagesSuccess(smsMessages) {
  return {
    type: FETCH_PATIENT_SMS_MESSAGES_SUCCESS,
    payload: {
      smsMessages,
    },
  };
}

export function updateSmsMessageTags(smsId, tags) {
  return {
    type: UPDATE_SMS_MESSAGE_TAGS_SUCCESS,
    payload: {
      smsId,
      tags,
    },
  };
}

export function sendSmsMessageSuccess(sentMessage, noteId) {
  return {
    type: SEND_SMS_TO_PATIENT_SUCCESS,
    payload: { sentMessage, noteId },
  };
}

export function markSmsMessageAsReadSuccess(smsId, userDisplayName) {
  return {
    type: MARK_SMS_AS_READ_SUCCESS,
    payload: {
      smsId,
      userDisplayName,
    },
  };
}

export function markSmsMessageAsUnreadSuccess(smsId) {
  return {
    type: MARK_SMS_AS_UNREAD_SUCCESS,
    payload: {
      smsId,
    },
  };
}

export function fetchPatientAdherenceData(patientId) {
  return {
    type: FETCH_PATIENT_ADHERENCE_DATA,
    payload: {
      patient_id: patientId,
    },
  };
}

export function fetchPatientAdherenceHistory(patientId) {
  return {
    type: FETCH_PATIENT_ADHERENCE_HISTORY,
    payload: {
      patient_id: patientId,
    },
  };
}
export function fetchAdherenceComplianceStatus(patientId) {
  return {
    type: FETCH_PATIENT_ADHERENCE_COMPLIANCE_STATUS,
    payload: {
      patient_id: patientId,
    },
  };
}

export function clearPatientAdherenceHistory() {
  return {
    type: CLEAR_PATIENT_ADHERENCE_DATA,
    payload: {},
  };
}

export function reportDuplicatePatientProfiles(patient) {
  return {
    type: REPORT_DUPLICATE_PATIENT_PROFILES,
    payload: { patient },
  };
}

export function updatePatientProperties(values) {
  return {
    type: UPDATE_PATIENT_PROPERTIES,
    payload: {
      values,
    },
  };
}

export function refreshPatientPhones(patientId) {
  return {
    type: REFRESH_PATIENT_PHONES,
    payload: { patientId },
  };
}

export function fetchPatientBi(patientId) {
  return {
    type: FETCH_BI_INFO,
    payload: patientId,
  };
}
