import Moment from 'moment';
import { AxiosResponse } from 'axios';
import { BenefitsInvestigationId, PatientId, TherapyId } from 'interfaces/RecordTypes';
import { IBenefitsInvestigationDetailedHistoryItem } from 'interfaces/benefits-investigations/IBenefitsInvestigationDetailedHistoryItem';
import { IRequest } from 'interfaces/benefits-investigations/ncpdp/IRequest';
import { NcpdpStringUtils } from 'utils/ncpdp-string-utils';
import { TimeoutUtils } from 'utils/timeout-utils';
import { TypedHttp } from 'services/typed-http';
import {
  IBenefitInvestigationLatest,
  BenefitInvestigationStatus,
} from 'interfaces/redux/ITherapyBenefitsInvestigationLatest';
import { IIncidentTask } from 'interfaces/redux/task-types/IIncidentTask';
import {
  IBenefitsInvestigationApiResponse,
  IFullBenefitsInvestigationApiResponse,
  IBenefitsInvestigationApiRequest,
  IBenefitsInvestigationFormFields,
  IResponseData,
  IBenefitsInvestigationLatestApiResponse,
  IBenefitsInvestigationHistoryApiResponse,
  IBenefitsInvestigationHistory,
  IDur,
} from '../../interfaces/benefits-investigations';
import HTTP from '../http';

type BiHistoryDetail = {
  id: BenefitsInvestigationId;
  request: IRequest;
  response: Omit<IBenefitsInvestigationApiResponse, 'errors'>;
  request_string: string;
  response_string: string;
};

export class BenefitsInvestigationService {
  private getBiDetailedHistoryListRequest = (patientId: PatientId, therapyId: TherapyId) => {
    const url = `/patients/${patientId}/benefits-investigations/therapies/${therapyId}/history/list`;
    return {
      url: url,
    };
  };

  private getBiDetailedHistoryListRequestFromAr = (patientId: PatientId, arTaskId: number) => {
    const url = `/patients/${patientId}/benefits-investigations/ar/therapies/${arTaskId}/history/list`;
    return {
      url: url,
    };
  };

  private getBiDetailedHistoryRequest = (
    patientId: PatientId,
    therapyId: TherapyId,
    biId: BenefitsInvestigationId,
  ) => {
    const url = `/patients/${patientId}/benefits-investigations/therapies/${therapyId}/${biId}`;
    return {
      url,
    };
  };

  private getBiDetailedHistoryFromArRequest = (
    patientId: PatientId,
    arTaskId: number,
    biId: BenefitsInvestigationId,
  ) => {
    const url = `/patients/${patientId}/benefits-investigations/ar/${arTaskId}/${biId}`;
    return {
      url,
    };
  };

  private getSubmitBiRequest = (
    patientId: number,
    therapyId: number,
    formData: Omit<IBenefitsInvestigationFormFields, 'icdCode'> & { icdCode: string },
    durs?: IDur[],
  ): {
    url: string;
    payload: Partial<Omit<IBenefitsInvestigationApiRequest, 'icdCode'> & { icdCode: string }>;
  } => {
    const url = `/patients/${patientId}/benefits-investigations/therapies/${therapyId}`;
    const payload = { ...formData, durs: durs };
    return { url, payload: payload };
  };

  private getSubmitBiFromArRequest = (
    patientId: number,
    arTaskId: number,
    formData: Omit<IBenefitsInvestigationFormFields, 'icdCode'> & { icdCode: string },
    durs?: IDur[],
  ): {
    url: string;
    payload: Partial<Omit<IBenefitsInvestigationApiRequest, 'icdCode'> & { icdCode: string }>;
  } => {
    const url = `/patients/${patientId}/benefits-investigations/ar/${arTaskId}`;
    const payload = {
      patientId,
      arTaskId,
      ...formData,
      durs: durs,
    };
    return { url, payload: payload };
  };

  private getUpdateBenefitsInvestigationSavedRequest = (
    patientId: number,
    benefitsInvestigationId: number,
    saved: boolean,
  ): {
    url: string;
    payload: Partial<IBenefitsInvestigationApiRequest>;
  } => {
    const url = `/patients/${patientId}/benefits-investigations/${benefitsInvestigationId}/${saved}`;
    const payload = {};
    return { url, payload: payload };
  };

  private getFieldValueFromSegment = (
    response: IBenefitsInvestigationApiResponse,
    segmentId: string,
    fieldId: string,
    resultModifiers?: ((input: string) => string)[],
    combineMultipleFields = false,
    fieldCombineString = ' ',
  ): string | undefined => {
    const segment = response.AdditionalSegments.find(x => x.SegmentIdentification === segmentId);
    if (segment) {
      const fields = segment.Fields.filter(x => x.Id === fieldId);
      if (fields && fields.length > 0) {
        const result = !combineMultipleFields
          ? fields[0].Value?.trim() || ''
          : fields.map(x => x.Value?.trim()).join(fieldCombineString);
        if (!resultModifiers || resultModifiers.length === 0) {
          return result.trim();
        }
        const finalResult = resultModifiers.reduce(
          (prevValue, currentValue) => (prevValue = currentValue(prevValue)),
          result,
        );
        return finalResult.trim();
      }
    }
    return undefined;
  };

  /**
   * Given an NCPDP date like "20200201" returns the date in a given format
   */
  private prettyDate = (input: string, format = 'MM/DD/YYYY') => {
    const theMoment = Moment(input);
    const formattedMoment = theMoment.format(format);

    return formattedMoment;
  };

  public convertResponseForUi = (
    response: IFullBenefitsInvestigationApiResponse,
    therapy: { drug_name: string; days_supply: string },
    formData: { rxNo: string; bin: string; pcn: string; quantity: string; daysSupply: string },
  ): Partial<IResponseData> => {
    if (therapy) {
      // Set of response modifiers that converts an overpunched string to a pretty money string
      const overpunchToMoney = [
        NcpdpStringUtils.convertOverpunchNumber,
        NcpdpStringUtils.prettyNumberString,
        NcpdpStringUtils.moneyString,
      ];
      const apiResponseObj = response.responseObj;
      const coPay = this.getFieldValueFromSegment(apiResponseObj, '23', 'FI', overpunchToMoney);

      const responseObj: Partial<IResponseData> = {
        // reject messages and codes
        rejectMessages: response.errorCodeMessages,

        // SECTION: top left, old ui
        dateOfService: this.prettyDate(apiResponseObj.DateOfService),
        drug: therapy.drug_name,
        rxNumber: formData.rxNo,
        responseStatus: this.getFieldValueFromSegment(apiResponseObj, '21', 'AN'),
        // reversed
        patientPay: this.getFieldValueFromSegment(apiResponseObj, '23', 'F5', overpunchToMoney),
        planPaid: this.getFieldValueFromSegment(apiResponseObj, '23', 'F9', overpunchToMoney),

        // SECTION: top right, old ui
        claimMessage: this.getFieldValueFromSegment(apiResponseObj, '20', 'F4'),
        additionalMessage: this.getFieldValueFromSegment(
          apiResponseObj,
          '21',
          'FQ',
          undefined,
          true,
        ),
        rejectMessage: this.getFieldValueFromSegment(
          apiResponseObj,
          '21',
          'FB',
          undefined,
          true,
          ', ',
        ),

        // SECTION: left column, old ui
        bin: formData.bin,
        pcn: formData.pcn,
        // gpi
        routeOfAdm: this.getFieldValueFromSegment(apiResponseObj, '07', 'E2'),
        qtyDispensed: formData.quantity,
        daysSupply: formData.daysSupply,
        dawCode: this.getFieldValueFromSegment(apiResponseObj, '07', 'D8'),
        subClarCode: this.getFieldValueFromSegment(
          apiResponseObj,
          '07',
          'DK',
          undefined,
          true,
          ', ',
        ),
        // package size
        dateSubmitted: this.prettyDate(apiResponseObj.DateOfService),
        prefProdInc: this.getFieldValueFromSegment(apiResponseObj, '07', 'AS'),
        paNoAssigned: this.getFieldValueFromSegment(apiResponseObj, '07', 'EV'),
        accumDeductible: this.getFieldValueFromSegment(
          apiResponseObj,
          '23',
          'FC',
          overpunchToMoney,
        ),

        // SECTION: right column, old ui
        authorizationNo: this.getFieldValueFromSegment(apiResponseObj, '21', 'F3'),
        ingredientCost: this.getFieldValueFromSegment(apiResponseObj, '23', 'F6', overpunchToMoney),
        dispenseFee: this.getFieldValueFromSegment(apiResponseObj, '23', 'F7', overpunchToMoney),
        professionalFee: this.getFieldValueFromSegment(
          apiResponseObj,
          '23',
          'J1',
          overpunchToMoney,
        ),
        flatTax: this.getFieldValueFromSegment(apiResponseObj, '23', 'AW', overpunchToMoney),
        ptcTax: this.getFieldValueFromSegment(apiResponseObj, '23', 'AX', overpunchToMoney),
        incentiveAmountPaid: this.getFieldValueFromSegment(
          apiResponseObj,
          '23',
          'FL',
          overpunchToMoney,
        ),
        // secondary payor
        otherAmountRec: this.getFieldValueFromSegment(apiResponseObj, '23', 'J5', overpunchToMoney),
        exceededLimit: this.getFieldValueFromSegment(apiResponseObj, '23', 'FK', overpunchToMoney),
        coPay: coPay || '-',
        coInsurance: this.getFieldValueFromSegment(apiResponseObj, '23', '4U', overpunchToMoney),
        // plan patient
        // amt att to prod sel
        amtAttToProdSelBrandDrug: this.getFieldValueFromSegment(apiResponseObj, '23', 'UK'),
        amtAttToProdSelBrandNonPreferredFormularySelection: this.getFieldValueFromSegment(
          apiResponseObj,
          '23',
          'UN',
          overpunchToMoney,
        ),
        amtAttToProdSelNonPreferredFormularySelection: this.getFieldValueFromSegment(
          apiResponseObj,
          '23',
          'UM',
          overpunchToMoney,
        ),

        errors: apiResponseObj.errors,
      };

      return responseObj;
    }
    return {};
  };

  private mapApiResponseToBILatestDTO = (
    response: IBenefitsInvestigationLatestApiResponse,
  ): IBenefitInvestigationLatest => ({
    id: response.id,
    therapyId: response.therapy_id,
    b1Copay:
      response.b1_copay != null
        ? NcpdpStringUtils.moneyString(response.b1_copay.toString())
        : undefined,
    b1PatientPay:
      response.b1_patient_pay != null
        ? NcpdpStringUtils.moneyString(response.b1_patient_pay.toString())
        : undefined,
    b1RejectionMessages: JSON.parse(response.b1_rejection_messages || '[]'),
    b1Status: response.b1_status as BenefitInvestigationStatus,
    b2Status: response.b2_status as BenefitInvestigationStatus,
    created: response.created,
    errorCodeMessages: response.error_code_messages,
    daysSupply: response.days_supply,
    quantity: response.quantity,
  });

  private mapHistoryResponseToBIHistoryDTO = (
    response: IBenefitsInvestigationHistoryApiResponse,
  ): IBenefitsInvestigationHistory => {
    return {
      created: response.created,
      b1Status: response.b1_status as BenefitInvestigationStatus,
      b2Status: response.b2_status as BenefitInvestigationStatus,
      b1Copay:
        response.b1_copay != null
          ? NcpdpStringUtils.moneyString(response.b1_copay.toString())
          : undefined,
      b1PatientPay:
        response.b1_patient_pay != null
          ? NcpdpStringUtils.moneyString(response.b1_patient_pay.toString())
          : undefined,
      createdBy: response.created_by,
      pbmInsuranceId: response.pbm_insurance_id,
    };
  };

  /**
   * Makes the API request to load bi history list
   */
  private requestBiHistoryList = async (
    patientId: PatientId,
    therapyId: TherapyId,
  ): Promise<IBenefitsInvestigationDetailedHistoryItem[]> => {
    const request = this.getBiDetailedHistoryListRequest(patientId, therapyId);
    // eslint-disable-next-line no-useless-catch
    try {
      const results = await TypedHttp.Get<IBenefitsInvestigationDetailedHistoryItem[]>(request.url);
      return results.data;
    } catch (error) {
      throw error;
    }
  };

  private requestBiHistoryListFromAr = async (
    patientId: number,
    arTaskId: number,
  ): Promise<IBenefitsInvestigationDetailedHistoryItem[]> => {
    const request = this.getBiDetailedHistoryListRequestFromAr(patientId, arTaskId);
    // eslint-disable-next-line no-useless-catch
    try {
      const results = await TypedHttp.Get<IBenefitsInvestigationDetailedHistoryItem[]>(request.url);
      return results.data;
    } catch (error) {
      throw error;
    }
  };

  /**
   * Makes the API request to load detailed bi information
   */
  private requestBiHistoryDetail = async (
    patientId: PatientId,
    therapyId: TherapyId,
    biId: BenefitsInvestigationId,
  ): Promise<BiHistoryDetail> => {
    const request = this.getBiDetailedHistoryRequest(patientId, therapyId, biId);
    // eslint-disable-next-line no-useless-catch
    try {
      const results = await TypedHttp.Get<BiHistoryDetail>(request.url);
      return results.data;
    } catch (error) {
      throw error;
    }
  };

  private requestBiHistoryDetailFromAr = async (
    patientId: PatientId,
    therapyId: TherapyId,
    biId: BenefitsInvestigationId,
  ): Promise<BiHistoryDetail> => {
    const request = this.getBiDetailedHistoryFromArRequest(patientId, therapyId, biId);
    // eslint-disable-next-line no-useless-catch
    try {
      const results = await TypedHttp.Get<BiHistoryDetail>(request.url);
      return results.data;
    } catch (error) {
      throw error;
    }
  };

  public getResponse = async (
    patientId: number,
    therapyId: number,
    therapy: any,
    formData: Omit<IBenefitsInvestigationFormFields, 'icdCode'> & { icdCode: string },
    durItems?: IDur[],
  ): Promise<{
    converted: Partial<IResponseData>;
    newIncident?: IIncidentTask;
    b1Status?: string;
    b2Status?: string;
  }> => {
    const requestDetails = this.getSubmitBiRequest(patientId, therapyId, formData, durItems);
    const response = (await HTTP.post(
      requestDetails.url,
      requestDetails.payload,
      {},
    )) as AxiosResponse<IFullBenefitsInvestigationApiResponse>;
    const converted = this.convertResponseForUi(response.data, therapy, formData);
    converted.internalId = response.data.benefitInvestigationId;
    return {
      converted: converted,
      newIncident: response.data.newIncident,
      b1Status: response.data.b1Status,
      b2Status: response.data.b2Status,
    };
  };

  public getBiResponseFromAr = async (
    patientId: number,
    arTaskId: number,
    formData: Omit<IBenefitsInvestigationFormFields, 'icdCode'> & { icdCode: string },
    durItems: IDur[],
  ): Promise<{
    converted: Partial<IResponseData>;
    newIncident?: IIncidentTask;
    b1Status?: string;
    b2Status?: string;
  }> => {
    const requestDetails = this.getSubmitBiFromArRequest(patientId, arTaskId, formData, durItems);
    const response = (await HTTP.post(
      requestDetails.url,
      requestDetails.payload,
      {},
    )) as AxiosResponse<IFullBenefitsInvestigationApiResponse>;
    const converted = this.convertResponseForUi(
      response.data,
      { drug_name: formData.drugNameNdc, days_supply: formData.daysSupply },
      formData,
    );
    converted.internalId = response.data.benefitInvestigationId;
    return {
      converted: converted,
      newIncident: response.data.newIncident,
      b1Status: response.data.b1Status,
      b2Status: response.data.b2Status,
    };
  };

  public getMostRecentBISubsmissionByPatientIdTherapyId = async (
    patientId: number,
    therapyId: number,
  ): Promise<IBenefitInvestigationLatest[]> => {
    const response: AxiosResponse = await HTTP.get(
      `/patients/${patientId}/${therapyId}/benefits-investigations/latest`,
      {},
    );
    return response.data.map(this.mapApiResponseToBILatestDTO);
  };

  public getLatestPaidSoftwareCertIdByPatientId = async (patientId: number): Promise<string> => {
    const response: AxiosResponse = await HTTP.get(
      `/patients/${patientId}/benefits-investigations/latest-software-cert-id`,
    );
    return response.data;
  };

  public updateBenefitsInvestigationSaved = async (
    patientId: number,
    benefitsInvestigationId: number,
    saved: boolean,
  ): Promise<IBenefitInvestigationLatest> => {
    const requestDetails = this.getUpdateBenefitsInvestigationSavedRequest(
      patientId,
      benefitsInvestigationId,
      saved,
    );

    const response: AxiosResponse = await HTTP.post(requestDetails.url, requestDetails.payload, {});
    return this.mapApiResponseToBILatestDTO(response.data);
  };

  public getLatestTherapyBenefitInvestigationsByPatientId = async (
    patientId: number,
  ): Promise<IBenefitInvestigationLatest[]> => {
    const response: AxiosResponse = await HTTP.get(
      `/patients/${patientId}/benefits-investigations/latest`,
      {},
    );

    return response.data.map(this.mapApiResponseToBILatestDTO);
  };

  public getBenefitsInvestigationHistory = async (
    patientId: number,
    therapyId: number,
  ): Promise<any> => {
    const response: AxiosResponse = await HTTP.get(
      `/patients/${patientId}/benefits-investigations/therapies/${therapyId}/history`,
      {},
    );
    return response.data.map(this.mapHistoryResponseToBIHistoryDTO);
  };

  /**
   * Loads the bi details. Does any error checking and ensures that the request
   * takes a certain amount of time.
   */
  public getHistoryDetail = async (
    patientId: PatientId,
    therapyId: TherapyId,
    biId: BenefitsInvestigationId,
  ): Promise<BiHistoryDetail> => {
    const waitPromise = TimeoutUtils.wait(250);
    const resultPromise = this.requestBiHistoryDetail(patientId, therapyId, biId);

    const [, results] = await Promise.all([waitPromise, resultPromise]);

    return results;
  };

  public getHistoryDetailFromAr = async (
    patientId: PatientId,
    arTaskId: number,
    biId: BenefitsInvestigationId,
  ): Promise<BiHistoryDetail> => {
    const waitPromise = TimeoutUtils.wait(250);
    const resultPromise = this.requestBiHistoryDetailFromAr(patientId, arTaskId, biId);

    const [, results] = await Promise.all([waitPromise, resultPromise]);

    return results;
  };

  /**
   * Load the bi history details list. Does any error checking and ensures that the request
   * takes a certain amount of time.
   */
  public getHistoryDetailList = async (
    patientId: PatientId,
    therapyId: TherapyId,
  ): Promise<IBenefitsInvestigationDetailedHistoryItem[]> => {
    const resultPromise = this.requestBiHistoryList(patientId, therapyId);
    const waitPromise = TimeoutUtils.wait(250);
    // eslint-disable-next-line no-useless-catch
    try {
      const [, results] = await Promise.all([waitPromise, resultPromise]);
      return results;
    } catch (error) {
      throw error;
    }
  };

  public getHistoryDetailListFromAr = async (patientId: number, arTaskId: number) => {
    const resultPromise = this.requestBiHistoryListFromAr(patientId, arTaskId);
    const waitPromise = TimeoutUtils.wait(250);
    // eslint-disable-next-line no-useless-catch
    try {
      const [, results] = await Promise.all([waitPromise, resultPromise]);
      return results;
    } catch (error) {
      throw error;
    }
  };
}
