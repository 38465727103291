import React from 'react';
import { Button } from '@mui/material';
import Paragraph from '../components/paragraph';
import BlueContainer from '../components/blue-container';
import PoweredBy from '../components/powered-by';

const buttonStyle = {
  width: '85%',
  height: '60px',
  fontSize: '16px',
  backgroundColor: '#73bfa0',
  color: 'white',
};

const TransitionPage = props => (
  <BlueContainer>
    <Paragraph type="title">
      We have to collect some information from you before we start your refill.
    </Paragraph>
    <Button style={buttonStyle} onClick={props.continue}>
      Start
    </Button>
    <PoweredBy />
  </BlueContainer>
);

export default TransitionPage;
