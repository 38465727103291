import { ITask } from 'interfaces/redux/ITasks';
import { ITherapy } from 'interfaces/redux/ITherapy';

import { createSelector } from '@reduxjs/toolkit';
import { IState } from 'interfaces/redux/IState';
import { find } from 'lodash';
import { IResourceLink } from 'interfaces/redux/IResourceLinks';

export const getTherapyName = (task: ITask, therapies: ITherapy[]) => {
  if (task && therapies) {
    const matchingTherapy = therapies.find(item => item.id === task.therapy_id);
    if (matchingTherapy) {
      return matchingTherapy.drug_name;
    }
  }

  if (task && task.drugs) {
    return task.drugs[0].drug_name;
  }

  return task.therapy_id;
};

const selectLinksData = (state: IState) => state.links.data;
const selectTasksData = (state: IState) => state.tasks.data;
export const getNextFCTask = (
  linksData: IState['links']['data'],
  tasksData: IState['tasks']['data'],
  taskIdArg: number,
) => {
  const foundLink = find(linksData as IResourceLink[], {
    resource_one_type: 'task_fill_coordination',
    resource_two_type: 'task_fill_coordination',
    relationship: 'parent',
    resource_one_id: taskIdArg,
  });
  const foundLinkTaskKey = `FC${foundLink?.resource_two_id}`;
  const linkedTask = tasksData[foundLinkTaskKey];
  return linkedTask ?? null;
};
export const getSelectNextFCTask =
  (linksData: IState['links']['data'], tasksData: IState['tasks']['data']) =>
  (taskIdArg: number) => {
    const foundLink = find(linksData as IResourceLink[], {
      resource_one_type: 'task_fill_coordination',
      resource_two_type: 'task_fill_coordination',
      relationship: 'parent',
      resource_one_id: taskIdArg,
    });
    const foundLinkTaskKey = `FC${foundLink?.resource_two_id}`;
    const linkedTask = tasksData[foundLinkTaskKey];
    return linkedTask ?? null;
  };

export const getLinkedFCTaskForId = createSelector(
  [selectLinksData, selectTasksData],
  getSelectNextFCTask,
);

export const getTaskKey = (task?: Pick<ITask, 'id' | 'taskType'>) =>
  task ? `${task.taskType}${task.id}` : '';
