import { UserRoles } from 'constants/enums';
import { TaskStatus } from 'interfaces/enums/TaskStatus';
import { TaskType } from 'interfaces/TaskType';
import { AllTaskTypesStatuses } from 'interfaces/enums/TaskStatuses';
import { IClinicalDataType } from './IClinicalDataType';
import { IPaymentMethodType } from './IPaymentMethodType';
import { IServiceGroup } from './IServiceGroup';
import { IStatusReason } from './IStatusReason';

interface IVaccine {
  id: number;
  name: string;
  effective_for: string;
  type: { id: number; type: string };
  category: { id: number; category: string };
  default_selected: boolean | null;
}

export interface IClinic {
  active: number;
  address?: string;
  branch_type: number;
  created: Date;
  created_by: number;
  customer_id: number;
  display_name?: string;
  end_date?: Date;
  go_live_date?: Date;
  id: number;
  name: string;
  npi: number;
  parent_id?: number;
  service_group_id?: number;
  speciality_ids?: number[];
  tags?: string[];
  updated: Date;
  updated_by: Date;
}

interface IUserRole {
  id: number;
  name: UserRoles;
  fallback_role_id: null | number;
}

interface IUser {
  id: number;
  username: string;
  display_name: string;
  active: boolean;
  selected: boolean;
  display: string;
  roles?: IUserRole[];
  hide_from_ui: boolean;
}

export interface ITrellisTherapeuticsClass {
  id: number;
  name: string;
}

export interface IServiceGroups {
  id: number;
  name: string;
  display_name: string;
}

interface IEnrollmentStatus {
  id: number;
  status: 'Undecided' | 'Opt in' | 'Opt out';
  reason: string | null;
}

interface IResourceState<S = AllTaskTypesStatuses> {
  resource_id: number;
  resource_name: TaskType;
  resource_state_id: number;
  reason_status_id: S;
  state_name: TaskStatus;
}

interface IState {
  abbreviation: string;
  id: number;
  name: string;
}

interface IRole {
  display_name: string;
  id: number;
  name: string;
  show_credentialed_at: boolean;
  show_customers_selector: boolean;
  show_licensed: boolean;
  licensed_required: boolean;
  show_site_selector: boolean;
  show_in_add_user_modal: boolean;
  fallback_role_id: number | undefined;
}

interface IMedicationStatus {
  code: string;
  status: string;
  description: string;
}

interface ISmsPredefinedMessage {
  id: number;
  name: string;
  content: string;
  resource_type: {
    id: number;
    name: TaskType;
  };
}

interface ITherapyType {
  id: number;
  type: string;
}

export interface IUpsPackagingTypes {
  id: number;
  packaging_type: string;
  ups_code: number;
}

export enum FillCycleReasonEnum {
  StartDateDelayed = 1,
  TherapyHeld = 2,
  NewFillCycle = 3,
  PrnMed = 4,
  MedSync = 5,
}

interface ITherapyFillCycleReason {
  id: FillCycleReasonEnum;
  description: string;
  new: number | boolean;
}

interface ICourierTypes {
  id: number;
  type_name: string;
  customer_id: number;
  is_active: number | boolean;
}
interface ILookups {
  allergyReactionTypes: { id: number; name: string }[];
  clinicalDataTypes: Record<number, IClinicalDataType>;
  courierTypes: ICourierTypes[];
  customerClinics: IClinic[];
  defaultDocumentLabels: { id: number; label: string }[];
  incidentCategories: { id: number; name: string }[];
  enrollmentStatuses: IEnrollmentStatus[];
  fdbSideEffects: { id: number; description: string }[];
  lookupStatusReasons: IStatusReason[];
  medicationStatus: IMedicationStatus[];
  paymentMethodTypes: IPaymentMethodType[];
  patientNotesTagTypes: { id: number; tag_type: string }[];
  resourceStates: IResourceState[];
  roles: IRole[];
  serviceGroups: IServiceGroup[];
  smsPredefinedMessages: ISmsPredefinedMessage[];
  states: IState[];
  therapyTypes: ITherapyType[];
  trellisTherapeuticsClasses: ITrellisTherapeuticsClass[];
  users: IUser[];
  vaccines: Record<string, IVaccine[]>;
  upsPackagingType: IUpsPackagingTypes[];
  lookupScheduleOutreachReasons: { id: number; name: string }[];
  allergyLookups: any;
  therapyFillCycleReasons: ITherapyFillCycleReason[];
  sourceNumber: string;
  specialPopulations: { id: number; type: string; rank: number; need_end_date: number }[];
  consecutiveTypes: { id: number; type: string }[];
}

export type {
  IVaccine,
  IUser,
  ILookups,
  IResourceState,
  IRole,
  ITherapyType,
  ITherapyFillCycleReason,
};
