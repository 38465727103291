import { IMedicationListItem } from 'interfaces/redux/IMedications';
import { MedicationStatus, TherapyTypes } from 'constants/enums';
import { IGroup, IRow } from 'components/medication-list-group-table';
import { TriStateSortOrder } from 'components/medication-list-group-table/types';
import { AdministrationStatus } from 'interfaces/redux/ITherapy';
import { IMedicationRowWithCustomDose, MedicationListColumns } from './types';

export const buildMedGroups = (
  medicationGroups: IMedicationListItem[],
  medications: IMedicationListItem[],
  statusFilters: MedicationStatus[] | null,
): IGroup[] => {
  const statuses = statusFilters || [];
  const filtered = filterMedsByStatuses(medicationGroups, statuses);
  const sorted = sortByEnrollmentAndAlphabetically(filtered);
  const result = buildTableGroupsByGpi(sorted, medications);
  return result;
};

export const filterMedsByStatuses = (
  medications: IMedicationListItem[],
  statuses: MedicationStatus[],
) => {
  if (!medications) {
    return [];
  }

  if (!statuses?.length) {
    return medications;
  }

  const gpisInStatusFilter: Record<string, boolean> = (medications || [])
    .map(({ status_code: statusCode, ...rest }) => ({
      ...rest,
      status_code: statusCode.toLowerCase(),
    }))
    .filter(medication => statuses.includes(medication.status_code as MedicationStatus))
    .reduce((accum, med) => ({ ...accum, [med.gpi]: true }), {});

  return medications.filter(medication => gpisInStatusFilter[medication.gpi]);
};

export const sortByEnrollmentAndAlphabetically = (medications: IMedicationListItem[]) => {
  return medications.sort((meda, medb) => {
    if (meda.therapy_id && !medb.therapy_id) {
      return -1;
    }
    if (medb.therapy_id && !meda.therapy_id) {
      return 1;
    }
    return meda.drug_name.localeCompare(medb.drug_name);
  });
};

export const buildTableGroupsByGpi = (
  medicationGroups: IMedicationListItem[],
  medications: IMedicationListItem[],
): IGroup[] => {
  const medicationGroupMap = new Map();
  let groups: IGroup[] = [];
  const convertMedicationToMedicationsRow = (medication: IMedicationListItem): IRow => ({
    identifier: medication.id,
    rowData: {
      ...medication,
      dose: `${medication.strength} ${medication.strength_unit_of_measure}`,
    } as IMedicationRowWithCustomDose,
  });
  medicationGroups.forEach(mg => {
    const medicationsForGpi = medications.filter(({ gpi }) => gpi === mg.gpi);
    medicationGroupMap.set(mg.gpi, {
      parent: convertMedicationToMedicationsRow(mg),
      children: !medicationsForGpi.length
        ? [convertMedicationToMedicationsRow(mg)]
        : medicationsForGpi.map(convertMedicationToMedicationsRow),
    });
  });
  groups = Array.from(medicationGroupMap.values());
  return groups;
};

export const buildMedicationIdLookup = (
  medicationGroups?: IMedicationListItem[],
  medications?: IMedicationListItem[],
): Record<number, IMedicationListItem> => {
  return [...(medicationGroups || []), ...(medications || [])].reduce((acc, medication) => {
    if (medication.id) {
      return {
        ...acc,
        [medication.id]: medication,
      };
    }
    return acc;
  }, {});
};

export const sortByColInOrder = (
  groups: IGroup[],
  column: MedicationListColumns,
  sortOrder: TriStateSortOrder,
): IGroup[] =>
  [...groups].sort((groupA, groupB) => {
    const valueA = groupA.parent.rowData[column] as keyof IMedicationRowWithCustomDose;
    const valueB = groupB.parent.rowData[column] as keyof IMedicationRowWithCustomDose;
    let comparison;
    if (column === 'end_dt' || column === 'start_dt') {
      const timeA = valueA ? new Date(valueA).getTime() : 0;
      const timeB = valueB ? new Date(valueB).getTime() : 0;
      if (timeA === timeB) {
        return 0;
      }
      if (timeA < timeB) {
        comparison = -1;
      } else {
        comparison = 1;
      }
    } else {
      if (valueA === valueB) {
        return 0;
      }
      if ((valueA || '') < (valueB || '')) {
        comparison = -1;
      } else {
        comparison = 1;
      }
    }
    const result = sortOrder === TriStateSortOrder.Asc ? comparison : comparison * -1;
    return result;
  });

const isEndState = (medicationStatusCode: MedicationStatus) => {
  return [
    MedicationStatus.Discontinued,
    MedicationStatus.NoGo,
    MedicationStatus.Completed,
    MedicationStatus.Deleted,
    MedicationStatus.Canceled,
    MedicationStatus.EnteredInError,
    MedicationStatus.Stopped,
  ].includes(medicationStatusCode);
};
const isActiveTherapy = (status?: AdministrationStatus) => {
  if (status === undefined) return false;
  return [
    AdministrationStatus.PreTherapy,
    AdministrationStatus.OnTherapy,
    AdministrationStatus.OnHold,
  ].includes(status);
};

export const disableStatusTransition = (medicationGroup: IMedicationListItem, name: string) =>
  name === 'status_code' && !!medicationGroup?.therapy;
