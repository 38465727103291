import { convertToArborDate } from 'models/time/arbor-date';
import { DataCollectStatus } from 'interfaces/enums/TaskStatuses/DataCollectStatus';
import { DC, FAILED_THERAPIES_ID } from 'constants/index';
import {
  getClinicalDataTypesMap,
  getDataTypeName,
  getDcReferenceAsText,
} from 'services/utils/data-collect';
import moment from 'moment';
import { ICounselingTask } from 'interfaces/redux/task-types/ICounselingTask';
import { useTypedSelector } from 'hooks/use-typed-selector';
import { IDataCollectTask, ICollectedItem } from 'interfaces/redux/task-types/IDataCollectTask';
import { IMedicationListItem } from 'interfaces/redux/IMedications';
import { ClinicalDataItem } from 'interfaces/clinical-data/ClinicalDataResponse';
import { useClinicalData } from 'containers/patient/clinical/use-clinical-data';
import { IDataCollectFetchFilters } from '../interfaces/IDataCollectFetchFilters';
import { IDCFetchFilterItem } from '../chart-review/interfaces/IDcSection';

const getMedicationsNameByGPI = (gpis: string, medications: IMedicationListItem[]) => {
  const result: Record<string, any> = [];
  gpis
    .split(',')
    .forEach((gpi: string) =>
      medications.find((med: any) => med.gpi.substr(0, 10) === gpi && result.push(med.drug_name)),
    );
  return result.join(', ');
};

export const getMapToCollectedItemWithTask =
  (clinicalDataTypesMap: Record<string, any>, medications: IMedicationListItem[]) =>
  (rawCollected: ClinicalDataItem): ICollectedItem => {
    const isFailedTherapy = rawCollected.clinicalDataTypeId === FAILED_THERAPIES_ID;
    let value = '-';
    if (rawCollected.value) {
      if (!isFailedTherapy) {
        value = rawCollected.value;
      } else {
        value = getMedicationsNameByGPI(rawCollected.value, medications);
      }
    }

    return {
      dataTypeId: rawCollected.clinicalDataTypeId,
      dataTypeName: getDataTypeName(clinicalDataTypesMap, rawCollected.clinicalDataTypeId),
      required: rawCollected.required ? 'Yes' : 'No',
      value,
      assessmentDateAsDate: moment.utc(rawCollected.assessmentDate).toDate(),
      assessmentDate: rawCollected.assessmentDate
        ? convertToArborDate(moment(rawCollected.assessmentDate))?.getUtcDate(true) ?? '-'
        : ' - ',
      reference: getDcReferenceAsText(
        clinicalDataTypesMap[rawCollected.clinicalDataTypeId].references,
        value,
      ),
      cannotCompleteReason: rawCollected.cannotCompleteReason ?? '-',
    };
  };

const useLatestDcs = (
  taskQty: number,
  currentSelectedTasks: ICounselingTask[],
  filters?: IDataCollectFetchFilters,
): IDataCollectTask[] => {
  const currentSelectedTasksVar = currentSelectedTasks || [];
  const patient = useTypedSelector(state => state.patient);
  const patientId = patient ? patient.id : null;
  const tasks = useTypedSelector(state => state.tasks.data);
  let therapiesData = useTypedSelector(state => state?.therapies?.data);
  therapiesData = therapiesData || {};
  const therapies = Object.values(therapiesData);
  const clinicalDataTypes = useTypedSelector(state => state.lookups.clinicalDataTypes);
  const clinicalDataTypesMap = clinicalDataTypes
    ? getClinicalDataTypesMap(clinicalDataTypes)
    : getClinicalDataTypesMap([]);
  // @ts-ignore
  const clinicalData = useClinicalData(patientId);
  const medications = useTypedSelector(state => state.medications.medicationGroups);

  let dcTasksWithCollectedItems: IDataCollectTask[] = [];

  // Loop through current selected tasks to extract collect
  currentSelectedTasksVar.forEach(currentTask => {
    // Get DCs task  filtering by DC type and therapy ID of current CLS task
    let dataCollectTasks = [] as IDataCollectTask[];

    dataCollectTasks = Object.values(tasks).filter(
      task => task.taskType === DC && task.status_id === DataCollectStatus.Reviewed,
    ) as IDataCollectTask[];

    let taskQtyVar = taskQty;
    let meetsFilterCriteriaTasks = [];
    let meetsClinicalItemsFilterCriteria = true;
    let meetsDCDateAgoFilterCriteria = true;
    let meetsTherapyFilterCriteria = true;
    let meetsFilterCriteria = true;

    meetsFilterCriteriaTasks = dataCollectTasks.filter(task => {
      meetsTherapyFilterCriteria =
        filters && filters.filteredTherapies && filters.filteredTherapies.length > 0
          ? filters?.filteredTherapies.some((t: IDCFetchFilterItem) => t.value === task.therapy_id)
          : true;
      meetsDCDateAgoFilterCriteria =
        filters && filters.dcDateAgo && task.reviewed_dt
          ? // @ts-ignore
            filters?.dcDateAgo.value < moment.utc(task.reviewed_dt)
          : true;
      meetsFilterCriteria = meetsTherapyFilterCriteria && meetsDCDateAgoFilterCriteria;
      return (
        task.taskType === DC && task.status_id === DataCollectStatus.Reviewed && meetsFilterCriteria
      );
    });

    dataCollectTasks = [...meetsFilterCriteriaTasks] as IDataCollectTask[];

    taskQtyVar = dataCollectTasks.length;
    // Sorting by created date and picking just first n (taskQty)
    const sortedByCreatedTasks = dataCollectTasks
      .sort(
        (firstTask, secondTask) =>
          new Date(secondTask?.created ?? '')?.getTime() -
          new Date(firstTask.created ?? '')?.getTime(),
      )
      .slice(0, taskQtyVar);
    const dataCollectTasksPerTherapy = sortedByCreatedTasks.map(dcTask => {
      const collectedItems: ClinicalDataItem[] = [];
      if (clinicalData) {
        const clinicalDataForTask = clinicalData.filter(data => {
          meetsClinicalItemsFilterCriteria =
            filters && filters.filteredClinicalItems && filters.filteredClinicalItems.length > 0
              ? filters?.filteredClinicalItems.some(
                  (ci: IDCFetchFilterItem) => ci.value === data.clinicalDataTypeId,
                )
              : true;
          return data.taskDataCollectId === dcTask.id && meetsClinicalItemsFilterCriteria;
        });
        collectedItems.push(...clinicalDataForTask);
      }
      return {
        ...dcTask,
        collectedItems: collectedItems.map(
          getMapToCollectedItemWithTask(clinicalDataTypesMap, medications),
        ),
      };
    });
    dcTasksWithCollectedItems = [...dcTasksWithCollectedItems, ...dataCollectTasksPerTherapy];
  });

  const retArr = dcTasksWithCollectedItems
    .filter((value, index, self) => index === self.findIndex(t => t.id === value.id))
    .filter(val => val.collectedItems && val.collectedItems.length > 0);
  return retArr;
};

export { useLatestDcs };
