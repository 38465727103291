import languages from 'languages';
import { format as formatPhone } from 'phone-fns';
import { isValidArray } from 'services/utils/common-service';
import { OPT_IN, SMS_MOBILE } from 'constants/index';
import { DeliveryMethodValue, PhoneUseEnum } from 'constants/enums';
import { displayAddress, displayPhone } from './task-service';

export const parseGoogleAddress = googleRespondArray => {
  const addressJson = {};
  const googleRespond = googleRespondArray[0];
  googleRespond.address_components.forEach(addressComponent => {
    const addressType = addressComponent.types[0];
    if (addressType === 'street_number' || addressType === 'route') {
      if (!addressJson.line1) {
        addressJson.line1 = addressComponent.long_name;
      } else {
        addressJson.line1 += ` ${addressComponent.long_name}`;
      }
    } else if (addressType === 'locality') {
      addressJson.city = addressComponent.long_name;
    } else if (addressType === 'administrative_area_level_1') {
      addressJson.state = addressComponent.short_name
        ? addressComponent.short_name
        : addressComponent.long_name;
    } else if (addressType === 'postal_code') {
      addressJson.zip = addressComponent.long_name;
    }
  });
  return addressJson;
};

const additionalLanguages = [
  {
    label: 'Rohingya',
    value: 'Rohingya',
  },
  {
    label: 'Hmong',
    value: 'Hmong',
  },
  {
    label: 'Creole - Haitian',
    value: 'Creole',
  },
  {
    label: 'Dinka',
    value: 'Dinka',
  },
  {
    label: 'American Sign Language (ASL)',
    value: 'American Sign Language (ASL)',
  },
  {
    label: 'Persian - Farsi',
    value: 'Persian - Farsi',
  },
  {
    label: 'Persian - Dari',
    value: 'Persian - Dari',
  },
  {
    label: 'Ilocano',
    value: 'Ilocano',
  },
  {
    label: 'Visayan',
    value: 'Visayan',
  },
  {
    label: 'Karen',
    value: 'Karen',
  },
  {
    label: 'Mandarin',
    value: 'Mandarin',
  },
  {
    label: 'Cantonese',
    value: 'Cantonese',
  },
  {
    label: 'Ha Kha Chin',
    value: 'Ha Kha Chin',
  },
];

export const getLanguageList = () => {
  const commonLanguages = [];
  languages.getAllLanguageCode().forEach(code => {
    const language = languages.getLanguageInfo(code);
    if (code === 'en') {
      commonLanguages.unshift({
        label: language.name,
        value: language.name,
      });
    } else {
      commonLanguages.push({
        label: `${language.name} - ${language.nativeName}`,
        value: language.name,
      });
    }
  });

  return commonLanguages.concat(additionalLanguages).sort((a, b) => {
    if (a.value === 'English') return -1;
    if (b.value === 'English') return 1;
    return a.value.localeCompare(b.value);
  });
};

// eslint-disable-next-line no-shadow
export const getLanguageDisplay = languages => {
  if (isValidArray(languages)) {
    return languages.map(i => i.value).join(', ');
  }
  return '';
};

export const getPreferredEmailDisplay = people => {
  if (isValidArray(people && people.filteredEmails)) {
    return people.filteredEmails[0].value;
  }
  return '';
};

export const getPreferredPhoneDisplay = people => {
  if (isValidArray(people && people.filteredPhones)) {
    return people.filteredPhones[0].value;
  }
  return '';
};

export const updatePhoneSmsAttributes = (newValues, initialMobileNumbers) => {
  if (!isValidArray(newValues)) return;
  // check each phone entry. If the number is of type Mobile and the value
  // is not one of the original mobile numbers fromwhen the form was opened then
  // it is a new Mobile number or one of the original mobile numbers was changed.
  // In either case a new opt-in message needs to be sent therefor the patient_choice and
  // is_opt_in_sent attributes are removed.
  newValues.forEach(value => {
    if (value.use === PhoneUseEnum.Mobile && !initialMobileNumbers.includes(value.value)) {
      delete value.patient_choice;
      delete value.is_opt_in_sent;
    }
  });
};

export function processPhoneData(phones, toDatabase) {
  if (toDatabase) {
    if (!isValidArray(phones)) return null;
    return phones.map(item => {
      // removes fromTime, and toTime since they are already parsed in period
      const {
        fromTime,
        toTime,
        sms,
        patient_choice: patientChoice,
        is_opt_in_sent: isOptInSent,
        ...phone
      } = item;
      const phoneJson = {
        ...phone,
        ...(phone.use === PhoneUseEnum.Mobile
          ? { sms, patient_choice: patientChoice, is_opt_in_sent: isOptInSent }
          : {}),
        ...(phone.deleted ? { deleted: true } : {}),
        value: formatPhone('(NNN) NNN-NNNN', phone.value),
        period: `${fromTime || ''}-${toTime || ''}`,
      };

      return phoneJson;
    });
  }
  if (!isValidArray(phones)) return [];
  return phones.map(phone => {
    if (phone.period) {
      const periodArr = phone.period.split('-');
      if (isValidArray(periodArr)) {
        [phone.fromTime, phone.toTime] = periodArr;
      }
    }
    // convert existing sms boolean to string
    if (typeof phone.sms === 'boolean') {
      if (phone.sms) {
        phone.sms = 'Opt in';
      } else {
        phone.sms = 'Opt out';
      }
    }
    return phone;
  });
}

export function assignRanking(list, preferIndex) {
  if (isValidArray(list)) {
    // If preferIndex is not 0
    if (preferIndex) {
      // swap the preferred one to the first of the array
      [list[0], list[preferIndex]] = [list[preferIndex], list[0]];
    }
    // assign rank based on their index
    list.forEach((e, index) => {
      e.rank = index + 1;
    });
  }
  return list;
}

export function assignKeys(list) {
  if (!isValidArray(list)) return null;
  let maxId = 0;
  // iterate the array to find the max id
  list.forEach(l => {
    if (Number.isNaN(Number(l.key))) {
      // if key is uuid then remove it
      l.key = null;
    } else {
      l.key = Number(l.key);
      maxId = Math.max(maxId, l.key);
    }
  });
  list.forEach(l => {
    // If current item doesn't have a valid key, set key base on the max id
    if (!l.key) {
      maxId += 1;
      l.key = maxId;
    }
  });
  return list;
}

export function assignRankingAndKeys(list, preferIndex) {
  return assignRanking(assignKeys(list), preferIndex);
}

// Keep deleted item and mark as deleted
export function archiveInfo(curList, prevList) {
  // If prev list is empty means patient have no item before, do nothing
  if (!isValidArray(prevList)) return curList;
  // If current list is empty means all items are deleted
  if (!isValidArray(curList)) {
    return prevList.map(pl => ({ ...pl, deleted: true }));
  }
  prevList.forEach(pl => {
    // Check patient original list, if item can not
    // find in current list means it got removed
    if (!curList.find(cl => cl.key === pl.key)) {
      pl.deleted = true;
      curList.push(pl);
    }
  });
  return curList;
}

export const formatPatientName = (patient, lastNameFirst) => {
  const firstNameParts = [patient.first_name, patient.middle_name];
  const lastNameParts = [patient.last_name, patient.suffix];
  const firstAsString = firstNameParts.filter(p => p).join(' ');
  const lastAsString = lastNameParts.filter(p => p).join(' ');
  if (lastNameFirst) {
    return `${lastAsString ? `${lastAsString}, ` : ''} ${firstAsString || ''}`.trim();
  }
  return `${firstAsString || ''} ${lastAsString || ''}`.trim();
};

export const formatPatientNameShort = (patient, lastNameFirst) => {
  const newPatientObject = { ...patient };
  newPatientObject.middle_name = undefined;
  return formatPatientName(newPatientObject, lastNameFirst);
};

export const formatContactName = contact => {
  if (!contact) return '';
  return `${formatPatientName(contact)} (${contact.relationship})`;
};

export const formatContactNameWithType = contact => {
  if (!contact) return '';
  let typeLabel = '';
  let contactName = `${contact.first_name} ${contact.last_name || ''}`.trim();
  if (contact.is_emergency) {
    typeLabel += 'Emergency Contact';
  }
  if (contact.is_responsible) {
    if (typeLabel) typeLabel += ', ';
    typeLabel += 'Responsible Party';
  }
  if (typeLabel) {
    contactName += ` (${typeLabel})`;
  }
  return contactName;
};

export const haveOptInEmail = emails => emails && emails.find(e => e.enrollment === 'Opt in');

export const haveOptInSms = phones => phones && phones.find(p => p.sms === 'Opt in');

export const getPreferredDeliveryMethod = patient => patient.preferred_rx_delivery_method || 1;

export const getFullEmailList = (patient, contacts) => {
  const list =
    patient && patient.emails
      ? [...patient.emails].map((email, index) => ({
          label: `${email.use} - ${email.value}`,
          value: email.rank || index + 1,
          rawEmail: email,
        }))
      : [];

  if (contacts) {
    Object.keys(contacts).forEach(contactId => {
      const contact = contacts[contactId];
      if (contact) {
        const name = formatContactName(contact);
        if (contact.emails) {
          Array.prototype.push.apply(
            list,
            contact.emails.map((email, index) => ({
              label: `${name} - ${email.value}`,
              value: email.rank || index + 1,
              rawEmail: email,
              contactId: Number(contactId),
            })),
          );
        }
      }
    });
  }

  return list.length
    ? list
    : [{ label: 'No email address in Arbor for this Patient', value: null }];
};

export const getFullPhoneList = (patient, contacts) => {
  const list =
    patient && patient.filteredPhones
      ? [...patient.filteredPhones].map((phone, index) => ({
          label: patient.nickname
            ? `${patient.nickname} ${patient.last_name} - ${displayPhone(phone)}`
            : `${formatPatientName(patient)} - ${displayPhone(phone)}`,
          value: phone.rank || index + 1,
          rawPhone: phone,
        }))
      : [];
  if (contacts) {
    Object.keys(contacts).forEach(contactId => {
      const contact = contacts[contactId];
      if (contact && contact.filteredPhones) {
        const name = formatContactName(contact);
        Array.prototype.push.apply(
          list,
          (contact.filteredPhones || []).map((phone, index) => ({
            label: `${name} - ${displayPhone(phone)}`,
            value: phone.rank || index + 1,
            rawPhone: phone,
            contactId: Number(contactId),
          })),
        );
      }
    });
  }
  return list.length ? list : [{ label: 'No Phones in Arbor for this Patient', value: null }];
};

export const getOptInSmsPhoneList = (patient, contacts) => {
  const list = getFullPhoneList(patient, contacts);
  return list.filter(
    phone =>
      phone.rawPhone &&
      phone.rawPhone.use === SMS_MOBILE &&
      phone.rawPhone.sms === OPT_IN &&
      phone.rawPhone.patient_choice,
  );
};

export const getOptInEmailList = (patient, contacts) => {
  const list = getFullEmailList(patient, contacts);
  return list.filter(email => email.rawEmail && email.rawEmail.enrollment === OPT_IN);
};

export const getFullAddressList = (patient, contacts, method) => {
  const list =
    patient && patient.filteredAddresses
      ? [...patient.filteredAddresses].map((address, index) => ({
          label: patient.nickname
            ? `${patient.nickname} ${patient.last_name} - ${displayAddress(address)}`
            : `${formatPatientName(patient)} - ${displayAddress(address)}`,
          value: address.rank || index + 1,
          rawAddress: address,
          key: address.key || null,
        }))
      : [];
  if (contacts) {
    Object.keys(contacts).forEach(contactId => {
      const contact = contacts[contactId];
      if (
        contact &&
        Boolean(contact.declined_to_provide_emergency_contact) === false &&
        Boolean(contact.doesnt_have_emergency_contact) === false
      ) {
        const name = formatContactName(contact);
        (contact.filteredAddresses || []).forEach((address, index) => {
          const addressOption = {
            label: `${name} - ${displayAddress(address)}`,
            value: address.rank || index + 1,
            rawAddress: address,
            contactId: Number(contactId),
            key: address.key || null,
          };
          list.push(addressOption);
        });
      }
    });
  }
  if (list.length === 0 && DeliveryMethodValue.Usps !== method) {
    list.push({ label: 'No Addresses in Arbor for this Patient', value: null, key: null });
  }
  return list;
};

export const getPreferredFromList = (list, patient, contactId) => {
  let result = '';
  if (patient && contactId) {
    result =
      (list.find(it => String(it.value) === '1' && it.contactId === contactId) || {}).label || '';
  }
  if (!result) {
    result = (list.find(it => String(it.value) === '1') || {}).label || '';
  }
  return result;
};

export const getPreferredFromListForFC = (list, patient, contactId) => {
  let result = null;
  if (patient && contactId) {
    result =
      list.find(
        it => it.key === patient.preferred_rx_delivery_entity_key && it.contactId === contactId,
      ) || {};
  }
  if (!result) {
    result = list.find(it => it.key === patient.preferred_rx_delivery_entity_key) || {};
  }
  return result;
};

export const getPreferredZipFromList = (list, patient, contactId) => {
  let result = '';
  if (patient && contactId) {
    const filteredList =
      list.find(it => String(it.value) === '1' && it.contactId === contactId) || {};
    result = filteredList && filteredList.rawAddress ? filteredList.rawAddress.zip : '';
  }
  if (!result) {
    const filteredList = list.find(it => String(it.value) === '1') || {};
    result = filteredList && filteredList.rawAddress ? filteredList.rawAddress.zip : '';
  }
  return result;
};

export const getPreferredEmailAddress = (patient, contacts) => {
  const list = getFullEmailList(patient, contacts);
  return getPreferredFromList(list, patient, patient?.preferred_contact_id ?? null);
};

export const getPreferredPhoneNumber = (patient, contacts) => {
  const list = getFullPhoneList(patient, contacts);
  return getPreferredFromList(list, patient, patient?.preferred_contact_id ?? null);
};

export const getPreferredAddress = (patient, contacts) => {
  const list = getFullAddressList(patient, contacts);
  return getPreferredFromList(list, patient, patient?.preferred_rx_delivery_contact_id ?? null);
};

export const getPreferredAddressForFC = (patient, contacts) => {
  const list = getFullAddressList(patient, contacts);
  return getPreferredFromListForFC(
    list,
    patient,
    patient?.preferred_rx_delivery_contact_id ?? null,
  );
};

export const getPreferredZipFromAddress = (patient, contacts) => {
  const list = getFullAddressList(patient, contacts);
  return getPreferredZipFromList(list, patient, patient?.preferred_rx_delivery_contact_id ?? null);
};

export const getPreferredZip = patient => {
  if (patient?.filteredAddresses?.length) {
    const preferredAddress = patient.filteredAddresses.find(address => address.rank === 1);
    return preferredAddress ? preferredAddress.zip : patient.filteredAddresses[0].zip;
  }
  return '';
};

export const getPatientPreferred = (patient, type) => {
  if (!patient || !isValidArray(patient[type])) return null;
  return patient[type].find(t => !t.deleted);
};

export const getPatientNameForVerify = patient => {
  let retName = '';
  if (patient.first_name) {
    retName += patient.first_name;
  }
  if (patient.middle_name) {
    retName += ` ${patient.middle_name}`;
  }
  if (patient.last_name) {
    retName += ` ${patient.last_name}`;
  }
  if (patient.suffix) {
    retName += ` ${patient.suffix}`;
  }
  return retName;
};

export function isValidContactMethod(person, contactMethod, preferredIndex) {
  if (contactMethod === 2) {
    if (person.email_options === 1) {
      return 'Declines to provide emails';
    }
    if (person.email_options === 2) {
      return 'No email to provide';
    }
    if (!isValidArray(person.filteredEmails)) {
      return 'No Email on File';
    }
    if (person.filteredEmails[preferredIndex].enrollment !== 'Opt in') {
      return 'Selected Email is not Opt-in';
    }
  } else if (contactMethod === 1 || contactMethod === 3) {
    if (person.is_declined_to_provide_phones) {
      return 'Declines to provide phones';
    }
    if (!isValidArray(person.filteredPhones)) {
      return 'No Phone on File';
    }
    if (contactMethod === 3) {
      if (person.filteredPhones[preferredIndex].sms !== 'Opt in') {
        return 'Selected phone sms is not Opt-in';
      }
    }
  }
  return null;
}

export function isValidDeliveryAddress(person, deliveryType) {
  if (deliveryType === 2 || deliveryType === 3) {
    if (person.is_declined_to_provide_addresses) {
      return 'Decline to provide addresses';
    }
    if (!isValidArray(person.filteredAddresses)) {
      return 'No Address on File';
    }
  }
  return null;
}

export function getCommunicationDisplay(entry) {
  return `${entry.use} - ${entry.value}`;
}
