import React, { useState } from 'react';
import { ListItem, List, Typography } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ReviewItem from 'components/fc-self-service/components/review-item';
import Question from 'components/fc-self-service/components/question';
import ChangesNeededConfirmationPage from '../../changes-needed-confirmation-page';

enum QUESTION {
  main = 0,
  confirmation = 1,
}
const EmptyCircle = (
  <div
    style={{
      height: '16px',
      width: '16px',
      border: '2px solid green',
      borderRadius: '50%',
      marginRight: '3px',
      marginLeft: '2px',
      padding: '2px',
    }}
  />
);

const CHANGES_NEEDED_PAGE = 3;
const IncludedTherapies = (props: any) => {
  const { classes } = props;
  const { saveAnswer, isReview, currentAnswers, onEdit, answerOptions, skipToPage, nextQuestion } =
    props;
  const keyName = 'therapies';
  const questionText = 'Confirm the medications in the order';
  const questionTextReview = questionText;
  const therapies = answerOptions?.therapies;

  // This is a multi-page question and this value sets which
  // question is rendered.
  const [questionIndex, setQuestionIndex] = useState(QUESTION.main);
  const [therapiesState, setTherapiesState] = useState(therapies);

  const internalHandler = (val: any) => {
    if (val === 'Next') {
      saveAnswer({ keyName, value: therapiesState });
      const allSpecialtyNonPRNTherapiesSelected = therapiesState.every(
        (item: any) => item.included || !item.is_specialty || item.as_needed,
      );
      if (allSpecialtyNonPRNTherapiesSelected) {
        nextQuestion();
      } else {
        setQuestionIndex(QUESTION.confirmation);
      }
    } else {
      const therapy = therapiesState[val];
      const currentValue = therapy.included;
      const newTherapiesState = [...therapiesState];
      newTherapiesState[val].included = !currentValue;
      setTherapiesState(newTherapiesState);
    }
  };

  const confirmationResponseHandler = (response: any) => {
    if (response === 1) {
      // Need to reset answers here in case patient removes a specialty therapy
      // as part of the review process
      return skipToPage(CHANGES_NEEDED_PAGE);
    }
    if (isReview) {
      onEdit();
    } else {
      setQuestionIndex(QUESTION.main);
    }
  };

  // Display the main question
  if (questionIndex === QUESTION.main && !isReview) {
    return (
      <Question
        questionText={questionText}
        keyName={keyName}
        list={therapiesState}
        response={internalHandler}
        buttons={{ button1Text: 'Next', button1Value: 'Next' }}
      />
    );
  }

  if (questionIndex === QUESTION.main && isReview) {
    return (
      <ReviewItem
        key={props.key}
        questionText={questionTextReview}
        currentAnswer={currentAnswers[keyName] ? 'Looks Good' : 'Changes Are Needed'}
        onEdit={onEdit}
        keyName={keyName}
      >
        <List className={classes.medicationList} dense>
          {therapies.map((therapy: any, index: number) => (
            <ListItem disableGutters key={index} data-qa-id={`therapy-${index}`}>
              {therapy.included ? <CheckCircleIcon className={classes.icon} /> : EmptyCircle}
              <Typography className={classes.medicationList}>{therapy.name}</Typography>
            </ListItem>
          ))}
        </List>
      </ReviewItem>
    );
  }

  // Display the confirmation question
  return <ChangesNeededConfirmationPage responseHandler={confirmationResponseHandler} />;
};
export default IncludedTherapies;
