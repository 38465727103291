import { Typography, Grid, Button, Modal } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import Divider from 'components/divider';
import React, { useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { compose } from 'recompose';
import { convertToArborDate } from 'models/time/arbor-date';
import { editTasks } from 'actions/action-tasks';
import { editPatient, dischargePatient, markPatientAsDeceased } from 'actions/action-patient';
import { styles } from './patient-demographics-styles';
import PatientDeceased from './patient-deceased';
import { PatientDischargeButton } from './patient-discharge-button';

export const PatientDischarge = props => {
  const { classes, clinicId, verifyValue, isDeceasedType } = props;

  const dispatch = useDispatch();
  const [displayDeceased, setDisplayDeceased] = useState(false);
  const [dischargeInProgress, setDischargeInProgress] = useState(false);

  const [tasksCancel, setTasksCancel] = useState(false);

  const selectedPatientId = useSelector(state => state.selectedPatientId);
  const patientState = useSelector(state => state.patient);
  const isRightPatient = patientState && patientState.id === selectedPatientId;

  const therapies = useSelector(state => state?.therapies?.data || {});
  const patient = isRightPatient ? patientState : {};

  const handleDischargePatient = async (tasksToCancel) => {
    const dischargeClinic =
      patient && patient.clinics && patient.clinics.length > 0
        ? patient.clinics.find(clinic => parseInt(clinic.id, 10) === parseInt(clinicId, 10))
        : null;
    if (dischargeClinic) {
      setDischargeInProgress(true);
      await dispatch(dischargePatient(patient.id, dischargeClinic));
      if (tasksToCancel.length) {
        dispatch(editTasks(patient.id, tasksToCancel));
      }
      setDischargeInProgress(false);
    }
    setTasksCancel(false);
  };

  const activeTherapies = Object.values(therapies).filter(
    therapy =>
      !isDeceasedType &&
      therapy.administration_status_category_id !== '4' &&
      therapy.managing_clinic_id === clinicId,
  );

  const activeTherapiesNames = activeTherapies.map(it => it.drug_name).join(', ');

  return (
    <div>
      <Grid container alignItems="center" className={classes.clinicItem}>
        <Grid item xs={7} className={classes.patientInfoRow}>
          <Grid container justifyContent="flex-start" alignItems="center">
            <Grid item xs={12}>
              <Typography className={classes.patientDischargeText}>
                {isDeceasedType ? `Mark ${verifyValue.name} as deceased?` : verifyValue.name}
              </Typography>
            </Grid>
            {!isDeceasedType &&
              therapies &&
              therapies.length &&
              therapies.some(
                therapy =>
                  therapy.managing_clinic_id === verifyValue.id &&
                  therapy.administration_status_category_id !== 4,
              ) && (
                <Grid item xs={12}>
                  {!!isDeceasedType ? ( // eslint-disable-line
                    <Typography variant="body2">
                      Doing this will discontinue all therapies and discharge the patient from all
                      clinics.
                    </Typography>
                  ) : (
                    <Typography variant="body2">
                      {/* eslint-disable-next-line max-len */}
                      {`Discontinue all active therapies (${activeTherapiesNames}) to discharge this patient from this clinic`}
                    </Typography>
                  )}
                </Grid>
              )}
            {isDeceasedType && (
              <Grid item xs={12}>
                <Typography variant="body2">
                  Doing this will discontinue all therapies and discharge the patient from all
                  clinics
                </Typography>
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item xs={2} align="right">
          <Grid container justifyContent="flex-end" alignItems="center">
            {verifyValue.admitted_start_dt
              ? `Admitted ${convertToArborDate(verifyValue.admitted_start_dt, true).getCustomerDate(
                  true,
                )}`
              : null}
          </Grid>
        </Grid>
        <Grid item xs={3} className={classes.patientInfoRow}>
          {patient.deceased !== 1 ? (
            <Grid container justifyContent="flex-end" alignItems="center">
              {isDeceasedType ? (
                <Button
                  type="button"
                  onClick={() => setDisplayDeceased(true)}
                  className={classes.verificationBtn}
                  variant="outlined"
                  id={`patient_${patient.id}_deceased_button`}
                >
                  Mark as deceased
                </Button>
              ) : (
                <PatientDischargeButton
                  verifyValue={verifyValue}
                  tasksCancel={tasksCancel}
                  classes={classes}
                  patient={patient}
                  activeTherapies={activeTherapies}
                  handleDischargePatient={handleDischargePatient}
                  setTasksCancel={setTasksCancel}
                  dischargeButtonLabel="Discharge"
                  isLoading={dischargeInProgress}
                />
              )}
            </Grid>
          ) : (
            <Grid container justifyContent="flex-end" alignItems="center">
              <Typography variant="body2">Patient is deceased</Typography>
            </Grid>
          )}
        </Grid>
        {isDeceasedType && displayDeceased && (
          <Modal open={displayDeceased} className={classes.modal} disableAutoFocus>
            <div className={classes.modalContent}>
              <PatientDeceased
                verifyValue={verifyValue}
                isDeceasedType
                cancelCallback={() => setDisplayDeceased(false)}
              />
            </div>
          </Modal>
        )}
      </Grid>
      {!isDeceasedType && (
        <Grid item xs={12}>
          <Divider />
        </Grid>
      )}
    </div>
  );
};

export default compose(
  withStyles(styles),
  connect(null, {
    editPatient,
    dischargePatient,
    markPatientAsDeceased,
    editTasks,
  }),
)(PatientDischarge);
