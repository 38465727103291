import React from 'react';

import { Controller } from 'react-hook-form';
import { renderMultipleDropdownAsync as RenderMultipleDropdownAsync } from 'components/form/field/redux-field';
import { IControlledFormField } from '../types';

interface IProps<TValue> extends IControlledFormField {
  percentWith?: number;
  provider: any;
  defaultValue: TValue[] | null;
  onChange?: (args: any) => void;
  getOptionLabel?: (option: any) => string;
  getOptionValue?: (option: any) => TValue;
}

export const ControlledMultipleDropdownAsync = <TValue extends string | number>(
  props: IProps<TValue>,
): JSX.Element => {
  return (
    <Controller
      defaultValue={props.defaultValue}
      control={props.control}
      name={props.name}
      rules={props.validations || {}}
      render={(ctrlProps: any) => (
        <RenderMultipleDropdownAsync
          provider={props.provider}
          width={props.percentWith && `${props.percentWith}%`}
          label={props.label}
          input={{
            value: ctrlProps?.field?.value,
            onBlur: ctrlProps?.field?.value,
            onChange: (args: TValue[]) => {
              ctrlProps?.field?.onChange(args);
              if (props.onChange) {
                props.onChange(args);
              }
            },
          }}
          meta={props.inputMetaData || {}}
          data-qa-id={props.qaId}
          disabled={props.disabled}
          placeholder={props.placeholder}
          menuPlacement="bottom"
          getOptionLabel={props.getOptionLabel}
          getOptionValue={props.getOptionValue}
        />
      )}
    />
  );
};
