import {
  convertLookupToConstants,
  dropdownFormater,
  changeItemPosition,
  dropdownFormaterToFilterOpts,
} from 'services/utils/common-service';
import {
  FETCH_ICD10S,
  FETCH_PHYSICIANS,
  FETCH_GPIS,
  FETCH_INSURANCE_TYPES,
  FETCH_USERS,
  FETCH_SERVICE_GROUPS,
  FETCH_CUSTOMER_CLINICS,
  FETCH_LOOKUPS,
  NONE_INSURANCE_TYPE_ID,
  FETCH_ALLERGY_LOOKUPS,
  FETCH_ALLERGY_REACTION_TYPES,
  FETCH_APPOINTMENT_CLINICS,
  OTHER,
  ADD_TRELLIS_THERAPEUTIC_CLASS,
  ADD_DATA_COLLECT_ITEM,
  EDIT_DATA_COLLECT_ITEM,
} from '../constants';

const initialState = {
  CONSTANTS: {},
  allergens: [],
  allergyLookups: [],
  clinicalDataTypes: {},
  customerClinics: [],
  courierTypes: [],
  appointmentClinics: [],
  diagnosisLookups: {},
  gpiLookups: {},
  incidentCategories: [],
  incidentExternalResponsibles: [],
  incidentLookupOverrideReasons: [],
  incidentReportingParties: [],
  incidentTypes: [],
  incomeTimePeriods: {},
  insuranceRelationships: {},
  insuranceTypes: {},
  medicalInsuranceTypes: [],
  medicationCategoryCodes: {},
  medicationErrorCategories: [],
  medicationErrorTypes: [],
  medicationStatus: [],
  paymentMethodTypes: [],
  physicianLookups: {},
  planSponsors: [],
  resourceStates: [],
  roles: [],
  serviceGroups: [],
  specialPopulations: [],
  states: [],
  therapyDispensingPharmacyReasons: [],
  therapyTypes: [],
  trellisTherapeuticsClasses: [],
  tprHubs: [],
  users: [],
  lookupStatuses: [],
  lookupStatusReasons: [],
  upsPackagingType: {},
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_LOOKUPS: {
      const { lookups } = payload.data;
      if (lookups) {
        const medicalInsuranceTypes = lookups.lookup_insurance_type.filter(
          insuranceType => insuranceType.id !== NONE_INSURANCE_TYPE_ID,
        );
        const {
          lookup_enrollment_status: enrollmentStatuses,
          lookup_insurance_relationship: insuranceRelationships,
          lookup_income_periods: incomeTimePeriods,
          lookup_therapy_fill_cycle_reasons: therapyFillCycleReasons,
          lookup_clinical_data: clinicalDataTypes,
          lookup_courier_type: courierTypes,
          lookup_patient_questionnaires: patientQuestionnaires,
          lookup_patient_document_labels: defaultDocumentLabels,
          lookup_incident_categories: incidentCategories,
          lookup_incident_types: incidentTypes,
          lookup_medication_error_types: medicationErrorTypes,
          lookup_medication_error_categories: medicationErrorCategories,
          lookup_incident_external_responsibles: incidentExternalResponsibles,
          lookup_incident_reporting_parties: incidentReportingParties,
          lookup_incident_override_reasons: incidentOverrideReasons,
          lookup_medication_status: medicationStatus,
          lookup_vaccines: vaccines,
          lookup_audit_constants: auditConstants,
          lookup_medication_category: medicationCategoryCodes,
          trellis_therapeutics_classes: trellisTherapeuticsClasses,
          lookup_resource_states: resourceStates,
          lookup_special_populations: specialPopulations,
          lookup_external_dispensing_reason: therapyDispensingPharmacyReasons,
          lookup_payment_method_type: paymentMethodTypes,
          lookup_plan_sponsor: planSponsors,
          lookup_states: states,
          lookup_roles: roles,
          lookup_predefined_messages: smsPredefinedMessages,
          lookup_patient_note_tag_type: patientNotesTagTypes,
          lookup_fdb_side_effects: fdbSideEffects,
          lookup_status: lookupStatuses,
          lookup_status_reason: lookupStatusReasons,
          lookup_therapy_type: therapyTypes,
          lookup_ups_packaging_type: upsPackagingType,
          lookup_schedule_outreach_reasons: lookupScheduleOutreachReasons,
          lookup_manufacturer_hubs: tprManufacturerHubs,
          sms_source_number: smsSourceNumber,
          lookup_intervention_infection_types: lookupInterventionInfectionTypes,
          lookup_display_only_fill_coordination: lookupDisplayOnlyFillCoordination,
          consecutive_types: consecutiveTypes,
          lookup_recommended_appropriate_vaccines: lookupRecommendedAppropriateVaccines,
        } = lookups;
        // reorder, put the 'Other' option at the end

        const tprHubs = tprManufacturerHubs
          .map(item => ({ id: item.id, label: item.hub_name }))
          .sort((a, b) => a.label.localeCompare(b.label));
        const externalDispensingReasons = changeItemPosition(
          dropdownFormaterToFilterOpts(
            therapyDispensingPharmacyReasons,
            [7, 10],
            {
              name: 'external_dispensing_reason',
            },
            true,
          ),
          {
            value: OTHER,
          },
        );
        return {
          ...state,
          CONSTANTS: {
            INCIDENT_CATEGORIES: convertLookupToConstants(incidentCategories),
            INCIDENT_EXTERNAL_RESPONSIBLES: convertLookupToConstants(incidentExternalResponsibles),
            INCIDENT_LOOKUP_OVERRIDE_REASONS: convertLookupToConstants(incidentOverrideReasons),
            INCIDENT_REPORTING_PARTIES: convertLookupToConstants(incidentReportingParties),
            INCIDENT_TYPES: convertLookupToConstants(incidentTypes),
            MEDICATION_ERROR_TYPES: convertLookupToConstants(medicationErrorTypes),
          },
          auditConstants,
          clinicalDataTypes,
          courierTypes,
          defaultDocumentLabels,
          enrollmentStatuses,
          incidentCategories,
          incidentExternalResponsibles,
          incidentOverrideReasons,
          incidentReportingParties,
          incidentTypes,
          incomeTimePeriods,
          insuranceRelationships,
          medicalInsuranceTypes,
          medicationCategoryCodes,
          medicationErrorCategories,
          medicationErrorTypes,
          medicationStatus,
          patientNotesTagTypes,
          patientQuestionnaires,
          paymentMethodTypes: dropdownFormater(paymentMethodTypes, { name: 'payment_method_type' }),
          planSponsors,
          resourceStates,
          roles,
          smsPredefinedMessages,
          specialPopulations,
          states,
          therapyDispensingPharmacyReasons: externalDispensingReasons,
          therapyFillCycleReasons,
          trellisTherapeuticsClasses,
          vaccines,
          fdbSideEffects,
          lookupStatuses,
          lookupStatusReasons,
          therapyTypes,
          upsPackagingType,
          lookupScheduleOutreachReasons,
          lookupInterventionInfectionTypes,
          tprHubs,
          sourceNumber: smsSourceNumber,
          lookupDisplayOnlyFillCoordination,
          consecutiveTypes,
          lookupRecommendedAppropriateVaccines,
        };
      }
      return state;
    }
    case FETCH_ICD10S: {
      if (payload.data) {
        return {
          ...state,
          diagnosisLookups: payload.data.icd10s,
        };
      }
      return state;
    }
    case FETCH_GPIS: {
      if (payload.data) {
        return {
          ...state,
          gpiLookups: payload.data.drugs,
        };
      }
      return state;
    }
    case FETCH_PHYSICIANS: {
      if (payload.data) {
        return {
          ...state,
          physicianLookups: payload.data.physicians,
        };
      }
      return state;
    }
    case FETCH_INSURANCE_TYPES: {
      if (payload.data) {
        return {
          ...state,
          insuranceTypes: payload.data.types,
        };
      }
      return state;
    }
    case FETCH_USERS: {
      if (payload.data) {
        return {
          ...state,
          users: payload.data.users,
        };
      }
      return state;
    }
    case FETCH_SERVICE_GROUPS: {
      if (payload.data) {
        return {
          ...state,
          serviceGroups: payload.data.service_groups,
        };
      }
      return state;
    }
    case FETCH_CUSTOMER_CLINICS: {
      if (payload.data) {
        return {
          ...state,
          customerClinics: payload.data.clinics,
        };
      }
      return state;
    }
    case FETCH_APPOINTMENT_CLINICS: {
      if (payload.data) {
        return {
          ...state,
          appointmentClinics: payload.data.clinics,
        };
      }
      return state;
    }
    case FETCH_ALLERGY_LOOKUPS: {
      if (payload.data) {
        return {
          ...state,
          allergyLookups: payload.data.lookups,
        };
      }
      return state;
    }
    case FETCH_ALLERGY_REACTION_TYPES: {
      if (payload.data) {
        return {
          ...state,
          allergyReactionTypes: payload.data.reactions,
        };
      }
      return state;
    }
    case ADD_TRELLIS_THERAPEUTIC_CLASS: {
      const newTtcState = [...state.trellisTherapeuticsClasses, payload];
      return {
        ...state,
        trellisTherapeuticsClasses: newTtcState,
      };
    }
    case ADD_DATA_COLLECT_ITEM: {
      state.clinicalDataTypes[payload.category_id].dataTypes[payload.dataTypeId] = payload.data;
      return {
        ...state,
        clinicalDataTypes: { ...state.clinicalDataTypes },
      };
    }
    case EDIT_DATA_COLLECT_ITEM: {
      const itemToEdit =
        payload.previousCategoryId === payload.data.category_id
          ? state.clinicalDataTypes[payload.data.category_id].dataTypes[payload.dataTypeId]
          : state.clinicalDataTypes[payload.previousCategoryId].dataTypes[payload.dataTypeId];
      itemToEdit.name = payload.data.name;
      itemToEdit.isActive = payload.data.isActive;
      itemToEdit.loinc = payload.data.loinc;
      state.clinicalDataTypes[payload.data.category_id].dataTypes[payload.dataTypeId] = itemToEdit;
      if (payload.previousCategoryId !== payload.data.category_id) {
        delete state.clinicalDataTypes[payload.previousCategoryId].dataTypes[payload.dataTypeId];
      }
      return {
        ...state,
        clinicalDataTypes: { ...state.clinicalDataTypes },
      };
    }
    default:
      return state;
  }
};
