import React, { useState, useEffect, Fragment, useCallback } from 'react';
import { connect } from 'react-redux';
import tasksSelectJson from 'config/therapies/tasks-select.json';
import { convertMapToList } from 'reducers/helper-reducer';
import { getTasksOfATherapy, getTherapyStatusById } from 'services/utils/therapy-service';
import { convertToArborDate } from 'models/time/arbor-date';
import moment from 'moment';
import { Typography, Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useTheme } from '@mui/material/styles';
import {
  addTaskOldValues,
  getTaskUniqueId,
  getPendingTasks,
  getStatusNameByStatusId,
  getTaskName,
  getTaskNameLine2,
  getNeedsByDateOfTask,
} from 'services/utils/task-service';
import { CircleIndicatorIcon } from 'components/icons/icons';
import { INT } from 'constants/index';
import ModalForm from '../modal-form';

const useStyles = makeStyles(theme => ({
  indicatorLabel: {
    'fontWeight': 'bold',
    'marginLeft': theme.spacing(1),
    'marginRight': theme.spacing(4),
    '& i': {
      marginRight: theme.spacing(0.5),
    },
  },
  title: {
    marginBottom: theme.spacing(0.5),
  },
}));

const getCanceledStatusId = (taskStatuses, task) => {
  const statuses = taskStatuses[task.taskType.toLowerCase()] || [];
  const status = statuses.find(it => it.status === 'Canceled' && it.reason === null);
  return status ? status.id : task.status_id;
};

const TasksCancelForm = ({
  therapyIds,
  onClose,
  tasks,
  therapies,
  taskStatuses,
  allStatuses,
  onSubmit,
  filterTasks,
  title,
  instructions,
  reason,
  fillCycles,
  forceCancelIntervention = false,
  therapyStatuses,
  submitButtonText,
  cancelButtonText,
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(true);
  const [isSelectedAllINT, setIsSelectedAllINT] = useState(true);
  const theme = useTheme();
  const taskList = convertMapToList(tasks);

  const filtered = therapyIds.reduce((acc, therapyId) => {
    const therapyTasks = getPendingTasks(getTasksOfATherapy(taskList, therapyId, true)).filter(
      task => !filterTasks || filterTasks.includes(task.taskType),
    );
    Array.prototype.push.apply(acc, therapyTasks);
    return acc;
  }, []);

  useEffect(() => {
    if (!filtered.length) {
      setOpen(false);
      if (onSubmit) {
        onSubmit([]);
      }
    }
  }, []);

  const onChangeHandler = useCallback(
    values => {
      const allTasks = filtered.map(task => `${task.taskType}${task.id}`);
      const selectedTasks = values.task_ids;
      const allINTSelected = allTasks.reduce((allSelected, task) => {
        if (!allSelected) return false;
        if (task.startsWith(INT)) return selectedTasks.includes(task);
        return allSelected;
      }, true);
      setIsSelectedAllINT(allINTSelected);
    },
    [filtered],
  );

  const headers = [
    {
      label: 'Task',
      id: 'task',
      getValue: task => (
        <>
          <Typography variant="body2">{getTaskName(task)}</Typography>
          <Typography variant="body2">{getTaskNameLine2(task, allStatuses)}</Typography>
        </>
      ),
    },
    {
      label: 'Status',
      id: 'status',
      getValue: task => getStatusNameByStatusId(task.status_id, taskStatuses),
    },
    {
      label: 'NBD',
      id: 'nbd',
      getValue: task => getNeedsByDateOfTask(fillCycles, task),
    },
    {
      label: 'Follow Up Date',
      id: 'followup',
      getValue: task => {
        const date = task.followup_dt;
        return date ? convertToArborDate(task.followup_dt, true).getCustomerDate(true) : '-';
      },
    },
  ];

  return (
    <ModalForm
      title={title}
      instructions={instructions}
      open={open}
      disableSubmit={!isSelectedAllINT}
      jsonForm={tasksSelectJson}
      formId={`tasks-cancel-${therapyIds.join('')}`}
      formName="select"
      data={{
        task_ids: filtered.map(task => `${task.taskType}${task.id}`),
      }}
      alwaysEnabled
      providers={{
        table: {
          headers,
          data: filtered.map(task => ({
            ...task,
            id: `${task.taskType}${task.id}`,
          })),
          group: dataItem => {
            const therapy = therapies[dataItem.therapy_id];
            if (therapy) {
              const adminStatus = getTherapyStatusById(
                therapyStatuses.therapyAdministrationStatuses,
                therapy.administration_status_id,
              );
              return {
                id: dataItem.therapy_id,
                name: (
                  <>
                    <Typography variant="h6" className={classes.title}>
                      {therapy.drug_name}
                    </Typography>
                    <Grid container direction="row" justifyContent="flex-start">
                      <Grid item>
                        <Typography variant="body2">Administration:</Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant="body2" className={classes.indicatorLabel}>
                          <CircleIndicatorIcon
                            size={12}
                            color={theme.palette.map.therapyAdministration[adminStatus.status]}
                          />
                          {adminStatus.status}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant="body2">Dispensing:</Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant="body2" className={classes.indicatorLabel}>
                          <CircleIndicatorIcon
                            size={12}
                            color={theme.palette.map.therapyEnrollment[therapy.dispensing_status]}
                          />
                          {therapy.dispensing_status}
                        </Typography>
                      </Grid>
                      {!!therapy.is_specialty && (
                        <>
                          <Grid item>
                            <Typography variant="body2">Clinical Support:</Typography>
                          </Grid>
                          <Grid item>
                            <Typography variant="body2" className={classes.indicatorLabel}>
                              <CircleIndicatorIcon
                                size={12}
                                color={
                                  theme.palette.map.therapyEnrollment[
                                    therapy.clinical_support_status
                                  ]
                                }
                              />
                              {therapy.clinical_support_status}
                            </Typography>
                          </Grid>
                        </>
                      )}
                    </Grid>
                  </>
                ),
              };
            }
            return {};
          },
        },
      }}
      onChange={forceCancelIntervention && onChangeHandler}
      onSubmit={values => {
        setOpen(false);
        const selectedTasks = filtered.filter(
          task => values.task_ids.indexOf(getTaskUniqueId(task)) > -1,
        );
        if (onSubmit) {
          return onSubmit(
            selectedTasks.map(task => {
              const arborDate = convertToArborDate(moment(), true);
              const newTask = {
                id: task.id,
                taskType: task.taskType,
                therapy_id: task.therapy_id,
                status_id: getCanceledStatusId(taskStatuses, task),
                // some task types will use cancel_dt, and some others cancel_date
                canceled_dt: arborDate.getCustomerDatetime(),
                canceled_date: arborDate.getCustomerDate(),
                discharged_date: arborDate.getCustomerDate(),
                status_id_prior_to_discharge: task.status_id,
              };
              if (reason) {
                // some task types will use additional_reason, and some others canceled_reason
                newTask.additional_reason = reason;
                newTask.canceled_reason = reason;
              }
              return addTaskOldValues(newTask, task);
            }),
          );
        }
        return true;
      }}
      submitButtonText={submitButtonText || 'Cancel tasks'}
      cancelButtonText={cancelButtonText || 'Cancel'}
      onCancel={() => {
        setOpen(false);
        if (onClose) {
          onClose();
        }
      }}
    />
  );
};

function mapStateToProps({
  tasks,
  therapies,
  selectedPatientId,
  taskStatuses,
  fillCycles,
  therapyStatuses,
}) {
  return {
    tasks: tasks.data,
    therapies: therapies.data,
    selectedPatientId,
    taskStatuses: taskStatuses.statuses,
    allStatuses: taskStatuses,
    fillCycles,
    therapyStatuses,
  };
}

export default connect(mapStateToProps)(TasksCancelForm);
