import React, { useState } from 'react';
import { compose } from 'recompose';
import { connect, useDispatch } from 'react-redux';
import { getFormValues } from 'redux-form';
import JsonForm from 'components/dynamic-form/json-form';
import { addTask, fetchTaskCounts } from 'actions/action-tasks';
import { workListChanged } from 'actions/action-patient';
import { toDbDateMomentFormat } from 'services/utils/date-service';
import { updateTherapy, editTherapy } from 'actions/action-therapies';
import { TASK_TYPES_MANAGING_CLINIC, QRE } from 'constants/index';
import IncidentForm from 'containers/tasks/incidents/incident-form';
import { noteTagTypes } from 'constants/lists';
import { NoteTagTypes as tagTypesIds } from 'constants/note-tag-types';
import { DispensingStatusValue, TaskTypesEnum } from 'constants/enums';
import { taskPaApprovedModalActions } from 'actions/action-task-pa-approved-modal';
import HTTP from 'services/http';
import { taskFCDispensingModalActions } from 'actions/action-task-fc-dispensing-modal';
import providerBuilder from '../providers/provider-builder';
import taskProviderHoc from './task-form-hoc';

function TaskAddForm(props) {
  const {
    classes,
    selectedPatientId,
    therapy,
    onCancel,
    taskType,
    therapyId,
    buttonIdPrefix,
    task,
    addTask, // eslint-disable-line no-shadow
    updateTherapy, // eslint-disable-line no-shadow
    fetchTaskCounts, // eslint-disable-line no-shadow
    formName,
    validTaskType,
  } = props;
  const [values, setValues] = useState({ ...task, therapy_id: therapyId });
  const providers = providerBuilder(taskType)(values, props);
  const dispatch = useDispatch();

  if (taskType === QRE) {
    const tagType = noteTagTypes.find(tag => tag.label === QRE);
    return (
      <IncidentForm
        therapy={therapy}
        tagTypeId={tagType?.value ? tagType.value : tagTypesIds.therapy}
        cancel={onCancel}
      />
    );
  }

  return (
    <JsonForm
      type={taskType}
      formName={formName}
      formId={`${taskType}-add`}
      jsonForm={providers.json}
      data={values}
      providers={providers}
      alwaysEnabled
      onFormChange={newValues => {
        setValues({
          ...task,
          therapy_id: therapyId,
          ...newValues,
        });
      }}
      onSubmit={addedTask => {
        const payload = {
          ...task,
          ...addedTask,
          therapy,
          patient_id: selectedPatientId,
          therapy_id: therapyId,
          taskType,
        };

        const sendSubmit = () =>
          addTask(payload, taskType).then(() => {
            workListChanged();
            if (validTaskType) {
              if (addedTask?.managing_clinic_id) {
                // eslint-disable-line
                therapy.managing_clinic_id = addedTask.managing_clinic_id;
                updateTherapy(therapyId, {
                  managing_clinic_id: addedTask.managing_clinic_id,
                });
              }
              therapy.therapy_id = therapyId;
              editTherapy(therapy);
            }
            fetchTaskCounts();
            if (onCancel) {
              onCancel();
            }
          });

        switch (taskType) {
          case TaskTypesEnum.PA:
            // eslint-disable-next-line no-case-declarations
            const request = `/task/pa/last-approved-task/${therapyId}`;
            return HTTP.get(request).then(({ data }) => {
              if (!data?.id) {
                return sendSubmit();
              }

              dispatch(
                taskPaApprovedModalActions.open({
                  newTask: payload,
                  lastApproved: data,
                  event: sendSubmit,
                }),
              );

              return {};
            });

          case TaskTypesEnum.FC:
            if (DispensingStatusValue.OptIn !== therapy.dispensing_status_id) {
              dispatch(taskFCDispensingModalActions.open());

              return {};
            }

            updateTherapy(therapyId, {
              days_supply: addedTask?.days_supply,
              needsby_date: toDbDateMomentFormat(addedTask?.needsby_date),
              is_needsby_per_protocol: addedTask.is_needsby_per_protocol,
            });

            return sendSubmit();

          default:
            return sendSubmit();
        }
      }}
      onCancel={onCancel}
      buttonIdPrefix={buttonIdPrefix}
    />
  );
}

function mapStateToProps(state, props) {
  const { selectedPatientId, lookups, auth } = state;
  let formName = 'add';
  const validTaskType = TASK_TYPES_MANAGING_CLINIC.some(v => v === props.taskType);
  if (validTaskType && !props.therapy?.managing_clinic_id) {
    formName = 'addTaskWithClient';
  }
  const formValues = getFormValues(`${props.taskType}-add`)(state);
  return {
    auth,
    formValues,
    validTaskType,
    formName,
    selectedPatientId,
    lookups,
  };
}

export default compose(
  connect(mapStateToProps, {
    workListChanged,
    addTask,
    editTherapy,
    updateTherapy,
    fetchTaskCounts,
  }),
  taskProviderHoc,
)(TaskAddForm);
