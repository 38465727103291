import React from 'react';
import {
  Grid,
  Button,
  ListItem,
  List,
  Typography,
  ListItemIcon,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import FormButton from '../button';

const Question = props => {
  const { classes, questionText, options, list, response, keyName, buttons, helperText, isEdit } =
    props;

  const EmptyCircle = (
    <div
      style={{ height: '21px', width: '21px', border: '2px solid green', borderRadius: '50%' }}
    />
  );

  return (
    <Box key={props.key} className={classes.container} data-qa-id={`page-${keyName}`}>
      <Grid container spacing={8.5}>
        <Grid item>
          <Typography className={classes.title} data-qa-id="question">
            {questionText}
          </Typography>
          {list && (
            <List
              data-qa-id="therapy-list"
              style={{ overflowY: 'auto', maxHeight: 'calc(100vh - 320px)' }}
            >
              {list.map((item, index) => (
                <ListItem disableGutters key={`${index}-${item}`} data-qa-id={`therapy-${index}`}>
                  <ListItemIcon className={classes.iconWrapper}>
                    <Button onClick={() => response(index)}>
                      {item.included ? <CheckCircleIcon className={classes.icon} /> : EmptyCircle}
                    </Button>
                  </ListItemIcon>
                  <Typography className={classes.itemListText} component="span">
                    {item.name}
                  </Typography>
                </ListItem>
              ))}
            </List>
          )}
        </Grid>
        {options && (
          <Grid item xs={12}>
            <FormControl variant="standard" sx={{ width: '100%' }}>
              <InputLabel id={keyName} className={classes.selectLabel}>
                {options.label}
              </InputLabel>
              <Select
                onChange={event => options.setSelectedValue(event.target.value)}
                value={options.selectedValue}
                className={classes.select}
                data-qa-id={`dropdown-${keyName}`}
              >
                {options.items.map((method, index) => (
                  <MenuItem
                    style={method.hideFromDropDown ? { display: 'none' } : {}}
                    key={index}
                    value={method}
                    data-qa-id={`option-${method.answerOptionsIndex}`}
                  >
                    {method.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        )}

        {buttons && (
          <Grid className={classes.btnContainer}>
            {helperText && <Grid pb={4}>{helperText}</Grid>}
            <Grid item xs={12}>
              <Button
                size="large"
                className={classes.btnLarge}
                onClick={() =>
                  response(
                    buttons.button1Value !== undefined && buttons.button1Value !== null
                      ? buttons.button1Value
                      : 1,
                  )
                }
                data-qa-id={`option-${buttons.button1Text}`}
              >
                {buttons.button1Text}
              </Button>
            </Grid>
            {buttons.button2Text ? (
              <Grid item xs={12}>
                <Button
                  size="large"
                  className={classes.btnLarge}
                  onClick={() => response(buttons.button2Value || 0)}
                  data-qa-id={`option-${buttons.button2Text}`}
                >
                  {buttons.button2Text}
                </Button>
              </Grid>
            ) : null}
          </Grid>
        )}
        {helperText && !buttons && <Grid>{helperText}</Grid>}
      </Grid>
      {options && (
        <Box marginTop="auto">
          <FormButton
            disabled={options.selectedValue.answerOptionsIndex === null}
            onClick={() => response(options.selectedValue)}
            data-qa-id={`option-${isEdit ? 'Save' : 'Next'}`}
          >
            {isEdit ? 'Save' : 'Next'}
          </FormButton>
        </Box>
      )}
    </Box>
  );
};

export default Question;
